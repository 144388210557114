import React, { useState, useEffect, useRef } from "react";
import { Spin, Layout } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import MyProcessing from "./../components/Assets/engineering.png";

const ProcessingCloseSaving = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const navigate = useNavigate();
	const location = useLocation();
	const data = location.state;

	const savingNameUpdate = data.savingName;
	const savingProcessIdUpdate = data.savingProcessId;
	const exchangeNameUpdate = data.exchangeName;
	const processingUpdate = data.processing;

	const [progress, setProgress] = useState(0);
	const [loading, setLoading] = useState(false);
	const hasRun = useRef(false); // Track if the effect has run

	const getSavingRefreshProcessing = async () => {
		setLoading(true);
		try {
			const user = JSON.parse(localStorage.getItem("user"));
			await axios.post(
				"/savings/close-saving-account/" +
					user._id +
					"/" +
					exchangeNameUpdate +
					"/" +
					savingProcessIdUpdate,
				{},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			navigate("/");
		} catch (error) {
			console.error("Error fetching :", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!hasRun.current) {
			hasRun.current = true; // Set the flag to true
			getSavingRefreshProcessing();
		}
	}, []);

	// useEffect(() => {
	// 	const duration = 90 * 1000; // 15 seconds in milliseconds
	// 	const interval = 100; // Update the progress every 100 milliseconds

	// 	const steps = duration / interval;
	// 	let currentStep = 0;

	// 	const updateProgress = () => {
	// 		currentStep += 1;
	// 		const newProgress = (currentStep / steps) * 100;
	// 		setProgress(newProgress);

	// 		if (currentStep < steps) {
	// 			// Continue updating the progress
	// 			setTimeout(updateProgress, interval);
	// 		} else {
	// 			// Navigate to another page after the progress is complete
	// 			navigate("/");
	// 		}
	// 	};

	// 	// Start updating the progress
	// 	updateProgress();

	// 	// Clean up the interval when the component unmounts
	// 	return () => clearTimeout(updateProgress);
	// }, [navigate]);

	return (
		<Layout>
			<div className="container" style={{ height: "75vh" }}>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						marginTop: "50px",
					}}
				>
					{loading && <Spin />}
					<br />
					<img alt="example" src={MyProcessing} />
					<br />
					<h1 align="center">
						{processingUpdate} <br /> {savingNameUpdate}
					</h1>
					<br />
					<p style={{ textAlign: "center" }}>
						Please wait Window will close when processing is complete.
					</p>
				</div>
			</div>
		</Layout>
	);
};

export default ProcessingCloseSaving;
