import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Divider, Slider, Card, Typography, Layout, Form, Button } from "antd";
import ResponsiveMenuDrawer from "../components/ResponsiveMenuDrawer.js"; // Adjust the path
import { ArrowLeftOutlined } from "@ant-design/icons";
import Chart from "react-apexcharts";
import axios from "axios";

const Strategies = () => {
	const { Title, Text } = Typography;
	const { Content } = Layout;

	const navigate = useNavigate();

	const [loginUser, setLoginUser] = useState("");

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user"));
		if (user) {
			setLoginUser(user);
		}
	}, []);

	const profileType = loginUser.profileType;

	const [initialInvestment, setInitialInvestment] = useState(1000);
	const [monthlyInvestment, setMonthlyInvestment] = useState(100);
	const [years, setYears] = useState(5);
	const [selectedStrategyIndex, setSelectedStrategyIndex] = useState(0);

	const [loading, setLoading] = useState(false);
	const [strategyData, setAllStrategies] = useState([]);

	useEffect(() => {
		const getAllStrategies = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/productMetrics/get-product-metrics",
					{},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllStrategies(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				//message.error('Fetch Issue With account');
			}
		};

		getAllStrategies();
	}, []);

	// const strategyData = [
	// 	{
	// 		_id: "676d1b6076699e49463eb548",
	// 		productName: {
	// 			_id: "665212eabdc9cabf5f972752",
	// 			productName: "Kingdom",
	// 			id: "665212eabdc9cabf5f972752",
	// 		},
	// 		products_name: "Kingdom",
	// 		cumulativeReturn: 2.4028,
	// 		annualReturn: 0.2374,
	// 		annualizedVolatility: 0.5932,
	// 		SRRI: 7,
	// 		last12Months: 0.813,
	// 		YTD: 0.007571,
	// 		id: "676d1b6076699e49463eb548",
	// 	},
	// 	{
	// 		_id: "676d1b6076699e49463eb549",
	// 		productName: {
	// 			_id: "665212eabdc9cabf5f972753",
	// 			productName: "Bitcoin Token",
	// 			id: "665212eabdc9cabf5f972753",
	// 		},
	// 		products_name: "Bitcoin Token",
	// 		cumulativeReturn: 1.9758,
	// 		annualReturn: 0.2088,
	// 		annualizedVolatility: 0.5155,
	// 		SRRI: 7,
	// 		last12Months: 1.3064,
	// 		YTD: 0.013066,
	// 		id: "676d1b6076699e49463eb549",
	// 	},
	// 	{
	// 		_id: "676d1b6076699e49463eb550",
	// 		productName: {
	// 			_id: "665212eabdc9cabf5f972754",
	// 			productName: "Ethereum Token",
	// 			id: "665212eabdc9cabf5f972754",
	// 		},
	// 		products_name: "Ethereum Token",
	// 		cumulativeReturn: 2.8001,
	// 		annualReturn: 0.2613,
	// 		annualizedVolatility: 0.6637,
	// 		SRRI: 7,
	// 		last12Months: 0.5694,
	// 		YTD: 0.004981,
	// 		id: "676d1b6076699e49463eb550",
	// 	},
	// 	{
	// 		_id: "676d1b6076699e49463eb548",
	// 		productName: {
	// 			_id: "665212eabdc9cabf5f972752",
	// 			productName: "Kingdom",
	// 			id: "665212eabdc9cabf5f972752",
	// 		},
	// 		products_name: "Kingdom",
	// 		cumulativeReturn: 2.4028,
	// 		annualReturn: 0.2374,
	// 		annualizedVolatility: 0.5932,
	// 		SRRI: 7,
	// 		last12Months: 0.813,
	// 		YTD: 0.007571,
	// 		id: "676d1b6076699e49463eb548",
	// 	},
	// 	{
	// 		_id: "676d1b6076699e49463eb549",
	// 		productName: {
	// 			_id: "665212eabdc9cabf5f972753",
	// 			productName: "Bitcoin Token",
	// 			id: "665212eabdc9cabf5f972753",
	// 		},
	// 		products_name: "Bitcoin Token",
	// 		cumulativeReturn: 1.9758,
	// 		annualReturn: 0.2088,
	// 		annualizedVolatility: 0.5155,
	// 		SRRI: 7,
	// 		last12Months: 1.3064,
	// 		YTD: 0.013066,
	// 		id: "676d1b6076699e49463eb549",
	// 	},
	// 	{
	// 		_id: "676d1b6076699e49463eb550",
	// 		productName: {
	// 			_id: "665212eabdc9cabf5f972754",
	// 			productName: "Ethereum Token",
	// 			id: "665212eabdc9cabf5f972754",
	// 		},
	// 		products_name: "Ethereum Token",
	// 		cumulativeReturn: 2.8001,
	// 		annualReturn: 0.2613,
	// 		annualizedVolatility: 0.6637,
	// 		SRRI: 7,
	// 		last12Months: 0.5694,
	// 		YTD: 0.004981,
	// 		id: "676d1b6076699e49463eb550",
	// 	},
	// 	{
	// 		_id: "676d1b6076699e49463eb548",
	// 		productName: {
	// 			_id: "665212eabdc9cabf5f972752",
	// 			productName: "Kingdom",
	// 			id: "665212eabdc9cabf5f972752",
	// 		},
	// 		products_name: "Kingdom",
	// 		cumulativeReturn: 2.4028,
	// 		annualReturn: 0.2374,
	// 		annualizedVolatility: 0.5932,
	// 		SRRI: 7,
	// 		last12Months: 0.813,
	// 		YTD: 0.007571,
	// 		id: "676d1b6076699e49463eb548",
	// 	},
	// ];

	const selectedStrategy = strategyData[selectedStrategyIndex];
	if (!selectedStrategy || typeof selectedStrategy.annualReturn !== "number") {
		console.error("Invalid selectedStrategy or annualReturn is undefined");
		return [];
	}

	const handleMoreInfo = (index) => {
		const selectedStrategy = strategyData[index];
		console.log("More information about:", selectedStrategy);
		// Add logic to navigate, display a modal, or fetch additional details.
		navigate("/StrategyDetails", {
			state: {
				selectedStrategy: selectedStrategy.products_name,
				idStrategy: selectedStrategy.productName._id,
			},
		});
	};

	// const calculateProjection = () => {
	// 	const data = [];
	// 	let totalInvestment = initialInvestment;
	// 	const monthlyRate = selectedStrategy.annualReturn;

	// 	for (let i = 1; i <= years * 1; i++) {
	// 		totalInvestment += monthlyInvestment;
	// 		totalInvestment += totalInvestment * monthlyRate;

	// 		if (i % 1 === 0) {
	// 			data.push({
	// 				year: i,
	// 				investment: totalInvestment.toFixed(2),
	// 			});
	// 		}
	// 	}
	// 	return data;
	// };

	// Calculate Projection
	const calculateProjection = () => {
		const data = [];
		let totalInvestment = initialInvestment;
		let initialInvestment_total = initialInvestment;
		const monthlyRate = selectedStrategy.annualReturn / 12; // Convert annual rate to monthly
		console.log("Initial totalInvestment:", totalInvestment);
		console.log("Monthly rate:", monthlyRate);

		// Loop over the number of years
		for (let i = 1; i <= years; i++) {
			for (let month = 1; month <= 12; month++) {
				// Add monthly investment
				totalInvestment += monthlyInvestment;
				initialInvestment_total += monthlyInvestment;

				// Apply monthly rate (interest)
				totalInvestment += totalInvestment * monthlyRate;
			}

			// Store the investment for the year
			data.push({
				year: i,
				investment: totalInvestment.toFixed(2),
				initialInvestment: initialInvestment_total.toFixed(2),
			});
			console.log(`Year ${i} totalInvestment:`, totalInvestment);
			console.log(
				`Year ${i} initialInvestment_total:`,
				initialInvestment_total
			);
		}

		return data;
	};

	const projectionData = calculateProjection();

	const chartOptions = {
		chart: { type: "line", width: "100%", toolbar: { show: false } },
		xaxis: {
			categories: projectionData.map((item) => `${item.year}Y`),
			title: { text: "Years" },
			labels: { style: { fontSize: "10px" }, rotate: -15 },
		},
		yaxis: {
			labels: {
				style: {
					fontSize: "10px", // Adjust font size based on screen width
				},
				formatter: (value) => {
					// Format the value as currency
					return new Intl.NumberFormat("en-US", {
						style: "currency",
						currency: "USD",
					}).format(value);
				},
			},
		},
		tooltip: {
			y: {
				formatter: (val) =>
					new Intl.NumberFormat("en-US", {
						style: "currency",
						currency: "USD",
					}).format(val),
			},
		},
		stroke: { curve: "straight" },
	};

	const chartSeries = [
		{
			name: selectedStrategy.products_name,
			data: projectionData.map((item) => parseFloat(item.investment)),
		},
	];

	const totalContribution = parseFloat(
		projectionData[projectionData.length - 1]?.initialInvestment || "0"
	);
	const projectedValue = parseFloat(
		projectionData[projectionData.length - 1]?.investment || "0"
	);

	const handleBack = () => {
		navigate("/");
	};

	// Define a function to handle button clicks and log the corresponding action
	const handleClick = (action) => {
		console.log(`${action} button clicked!`);
	};

	// Define a function to handle button clicks and log the corresponding action
	const handleMenuClick = (e) => console.log("Menu item clicked:", e.key);

	const handleHomeClick = () => {
		navigate("/");
	};

	const handleStrategiesClick = () => {
		navigate("/Strategies");
	};

	const handleExchangesClick = () => {
		navigate("/MyInvestments");
	};

	const handleAcademyClick = () => {
		navigate("/FinanceLiteracy");
	};

	const handleSettingsClick = () => {
		navigate("/MySettings");
	};

	const handleAdmin = () => {
		navigate("/ExplorerSettings");
	};

	const handleSignOut = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("investorProfile");
		navigate("/login");
	};

	return (
		<div className="container">
			<div>
				{/* Fixed back icon */}
				<ArrowLeftOutlined
					onClick={handleBack} // Navigate back to the previous page
					style={{
						top: 10, // Distance from the top
						fontSize: "24px", // Icon size
						cursor: "pointer", // Change the cursor to pointer on hover
						marginBottom: "10px",
						marginTop: "10px",
					}}
				/>

				<Card style={{ marginBottom: "10px" }}>
					<Title level={3}>
						Investment Strategy
						<br />
						<br />
						{strategyData[selectedStrategyIndex]?.products_name}
					</Title>
					<Divider />
					<Slider
						className="responsive-slider"
						min={0}
						max={strategyData.length - 1}
						step={1}
						value={selectedStrategyIndex}
						onChange={setSelectedStrategyIndex}
						marks={strategyData.reduce((marks, _, index) => {
							marks[index] = index + 1; // Display 1, 2, 3, etc.
							return marks;
						}, {})}
						tipFormatter={(value) => strategyData[value]?.products_name}
					/>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							marginTop: "16px",
						}}
					>
						<Button
							type="primary"
							style={{
								backgroundColor: "#7600ec", // Set background color
								borderColor: "#7600ec", // Set border color to match
								color: "white", // Set text/icon color to white
							}}
							onClick={() => handleMoreInfo(selectedStrategyIndex)}
						>
							More Information
						</Button>
					</div>
				</Card>

				<section>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							gap: "10px",
							marginTop: "10px",
							marginBottom: "10px",
							alignItems: "flex-start", // Align cards to the top
						}}
					>
						<Card
							style={{
								flexBasis: "33%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								flexGrow: 1, // Ensures all cards have equal size
							}}
						>
							<Title
								level={2}
								style={{ textAlign: "center", fontSize: "16px" }}
							>
								{((selectedStrategy.cumulativeReturn ?? 0) * 100).toFixed(2)}%
							</Title>
							<Divider />
							<div className="responsive-title">All Time</div>
						</Card>
						<Card
							style={{
								flexBasis: "33%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								flexGrow: 1, // Ensures all cards have equal size
							}}
						>
							<Title
								level={2}
								style={{ textAlign: "center", fontSize: "16px" }}
							>
								{((selectedStrategy.annualReturn ?? 0) * 100).toFixed(2)}%
							</Title>
							<Divider />
							<div className="responsive-title">Annual Average</div>
						</Card>
						<Card
							style={{
								flexBasis: "33%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								flexGrow: 1, // Ensures all cards have equal size
							}}
						>
							<Title
								level={2}
								style={{ textAlign: "center", fontSize: "16px" }}
							>
								{selectedStrategy.SRRI}
							</Title>
							<Divider />
							<div className="responsive-title">SRRI</div>
						</Card>
					</div>
				</section>

				<Card style={{ marginBottom: "10px" }}>
					<Title level={3}>Investment Projection</Title>
					<Form layout="vertical">
						<Form.Item label="Initial Investment">
							<Slider
								min={0}
								max={10000}
								step={100}
								value={initialInvestment}
								onChange={setInitialInvestment}
								marks={{
									0: "$0",
									5000: "$5,000",
									10000: "$10,000",
								}}
								tipFormatter={(value) => `$${value}`}
							/>
						</Form.Item>

						<Form.Item label="Monthly Investment">
							<Slider
								min={0}
								max={2000}
								step={50}
								value={monthlyInvestment}
								onChange={setMonthlyInvestment}
								marks={{
									0: "$0",
									1000: "$1,000",
									2000: "$2,000",
								}}
								tipFormatter={(value) => `$${value}`}
							/>
						</Form.Item>

						<Form.Item label="Projection Period (Years)">
							<Slider
								min={1}
								max={30}
								value={years}
								onChange={setYears}
								marks={{
									1: "1Y",
									10: "10Y",
									30: "30Y",
								}}
								tipFormatter={(value) => `${value} Years`}
							/>
						</Form.Item>
					</Form>
				</Card>

				<section>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							gap: "10px",
							marginBottom: "10px",
						}}
					>
						<Card style={{ flex: 1, textAlign: "center" }}>
							<Title level={4}>Your Contribution</Title>
							<Divider />
							<Text>
								{new Intl.NumberFormat("en-US", {
									style: "currency",
									currency: "USD",
								}).format(totalContribution)}
							</Text>
						</Card>
						<Card style={{ flex: 1, textAlign: "center" }}>
							<Title level={4}>Projected Value</Title>
							<Divider />
							<Text>
								{new Intl.NumberFormat("en-US", {
									style: "currency",
									currency: "USD",
								}).format(projectedValue)}
							</Text>
						</Card>
					</div>

					<Card>
						<Title level={4}>Projected Investment Growth</Title>
						<Divider />
						<Chart options={chartOptions} series={chartSeries} type="line" />
					</Card>
				</section>

				<section>
					<ResponsiveMenuDrawer
						profileType={profileType} // Pass profileType dynamically here
						handleMenuClick={handleMenuClick}
						handleHomeClick={handleHomeClick}
						handleStrategiesClick={handleStrategiesClick}
						handleExchangesClick={handleExchangesClick}
						handleAcademyClick={handleAcademyClick}
						handleAdmin={handleAdmin}
						handleSettingsClick={handleSettingsClick}
						handleSignOut={handleSignOut}
					/>
				</section>
			</div>
		</div>
	);
};

export default Strategies;
