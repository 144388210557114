import React, { useState, useEffect, useRef } from "react";
import { Progress, Spin } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Spinner from "../components/Spinner";

import Layout from "../components/Layout/Layout";

import MyProcessing from "./../components/Assets/engineering.png";

const ProcessingRefreshCryptoPricesDaily = () => {
	// useEffect(() => {
	//   window.scrollTo(0, 0);
	// }, []);

	const navigate = useNavigate();
	const location = useLocation();
	const data = location.state;

	const [progress, setProgress] = useState(0);
	const [loading, setLoading] = useState(false);
	const hasRun = useRef(false); // Track if the effect has run

	// // Inside your component
	// const effectRan = useRef(false);

	const getSavingRefreshProcessing = async () => {
		setLoading(true);
		try {
			const user = JSON.parse(localStorage.getItem("user"));
			await axios.post(
				"/cryptoPrices/refresh-cryptoPricesDaily/",
				{},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			navigate("/CryptoPrices");
		} catch (error) {
			console.error("Error fetching :", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!hasRun.current) {
			hasRun.current = true; // Set the flag to true
			getSavingRefreshProcessing();
		}
	}, []);

	return (
		<Layout>
			<div className="container" style={{ height: "75vh" }}>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						marginTop: "50px",
						height: "100%",
					}}
				>
					{loading && <Spin />}
					<br />
					<img alt="example" src={MyProcessing} />
					<br />
					<h1 align="center">
						Processing Crypto <br /> Prices Daily
					</h1>
					<br />
					<p style={{ textAlign: "center" }}>
						Please wait Window will close when processing is complete.
					</p>
				</div>
			</div>
		</Layout>
	);
};

export default ProcessingRefreshCryptoPricesDaily;
