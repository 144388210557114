import React, { useState, useEffect } from "react";
// import Navbar from "../components/Layout/Navbar";
import {
	Tabs,
	Form,
	Input,
	Button,
	Select,
	message,
	DatePicker,
	TimePicker,
	Spin,
	Layout,
	Card,
} from "antd";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import Spinner from "../components/Spinner";
import moment from "moment";
import { ArrowLeftOutlined } from "@ant-design/icons";

import ResponsiveMenuDrawer from "../components/ResponsiveMenuDrawer.js"; // Adjust the path

const MyContactEmail = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const navigate = useNavigate();

	const { TabPane } = Tabs;
	const { TextArea } = Input;

	const user = JSON.parse(localStorage.getItem("user"));
	const profileType = user.profileType;
	const investor = JSON.parse(localStorage.getItem("investorProfile"));

	const user_phoneNumber = user.phoneNumber;
	const user_agentName = user.agentName;
	const user_email = user.email;

	const [loading, setLoading] = useState(false);

	const [activeTab, setActiveTab] = useState("mobile");

	const handleTabChange = (key) => {
		setActiveTab(key);
	};

	const handleBack = () => {
		navigate("/MySettings");
	};

	const handleSettingsClick = () => {
		navigate("/MySettings");
	};

	// Define a function to handle button clicks and log the corresponding action
	const handleMenuClick = (e) => console.log("Menu item clicked:", e.key);

	const handleHomeClick = () => {
		navigate("/");
	};

	const handleStrategiesClick = () => {
		navigate("/Strategies");
	};

	const handleExchangesClick = () => {
		navigate("/MyInvestments");
	};

	const handleAcademyClick = () => {
		navigate("/FinanceLiteracy");
	};

	const handleAdmin = () => {
		navigate("/ExplorerSettings");
	};

	const handleSignOut = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("investorProfile");
		navigate("/login");
	};

	// const handleSubmitMobile = async (values) => {
	// 	try {
	// 		const user = JSON.parse(localStorage.getItem("user"));

	// 		// Combine the selected date and time into a single UTC datetime
	// 		const selectedDateTime = moment
	// 			.utc(
	// 				`${values.date.format("YYYY-MM-DD")} ${values.time.format(
	// 					"HH:mm:ss"
	// 				)}`
	// 			)
	// 			.toISOString(); // Convert to ISO string format for backend

	// 		setLoading(true);

	// 		const result = await axios.post(
	// 			"/phonecontacts/add-phonecontact",
	// 			{
	// 				serviceRating: parseInt(values.serviceRating),
	// 				agentName: user_agentName,
	// 				userid: user._id,
	// 				// Add the selected date and time
	// 				contactDateTime: selectedDateTime, // Pass the UTC datetime here
	// 			},
	// 			{
	// 				headers: {
	// 					"Content-Type": "application/json",
	// 					Authorization: `Bearer ${user.token}`,
	// 				},
	// 			}
	// 		);

	// 		setLoading(false);
	// 		message.success("Thank you, we will contact you as soon as possible!");
	// 		navigate("/");
	// 	} catch (error) {
	// 		setLoading(false);
	// 		message.error(
	// 			"There was an issue submitting the form. Please try again."
	// 		);
	// 	}
	// };

	const handleSubmitEmail = (values) => {
		// console.log('Submitted Email:', values);
		try {
			const user = JSON.parse(localStorage.getItem("user"));

			setLoading(true);
			const result = axios.post(
				"/emailcontacts/add-emailcontact",
				{
					...values,
					serviceRating: parseInt(values.serviceRating),
					agentName: user_agentName,
					userid: user._id,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			setLoading(false);
			message.success("Thank you, we will contact you as soon as possible!");
			setLoading(false);
			navigate("/");
		} catch (error) {
			setLoading(false);
		}
	};

	// Disable hours outside of 9 AM - 8 PM (09:00 - 20:00)
	const disabledHours = () => {
		const hours = [];
		for (let i = 0; i < 24; i++) {
			if (i < 9 || i >= 20) {
				hours.push(i);
			}
		}
		return hours;
	};

	return (
		<Layout>
			{/* <Navbar /> */}
			<div className="container" style={{ height: "100vh" }}>
				{loading && <Spin />}
				<div>
					{/* Fixed back icon */}
					<ArrowLeftOutlined
						onClick={handleBack} // Navigate back to the previous page
						style={{
							top: 10, // Distance from the top
							fontSize: "24px", // Icon size
							cursor: "pointer", // Change the cursor to pointer on hover
							marginBottom: "10px",
							marginTop: "10px",
						}}
					/>

					<section>
						<Card className="card-financial-summary">
							<Form
								layout="vertical"
								name="phoneNumberForm"
								onFinish={handleSubmitEmail}
							>
								<Form.Item
									label={
										<div>
											<h4>EMAIL</h4>
											<p>
												Use this form to express your doubts or request more
												information.
											</p>
										</div>
									}
									name="email"
								>
									<Input
										disabled
										defaultValue={user_email}
										style={{ width: "100%", color: "black", textAlign: "left" }}
									/>
								</Form.Item>

								<Form.Item
									label="Subject"
									name="subject"
									rules={[
										{ required: true, message: "Please select the subject!" },
									]}
								>
									<Select>
										<Select.Option value="GENERAL">GENERAL</Select.Option>
										<Select.Option value="CAMPAIGNS">CAMPAIGNS</Select.Option>
										<Select.Option value="PERSONAL DATA">
											PERSONAL DATA
										</Select.Option>
										<Select.Option value="DOCUMENTS">DOCUMENTS</Select.Option>
										<Select.Option value="RECEIPT">RECEIPT</Select.Option>
										<Select.Option value="RECEIPTS FOR PAYMENT">
											RECEIPTS FOR PAYMENT
										</Select.Option>
										<Select.Option value="OTHERS">OTHERS</Select.Option>
									</Select>
								</Form.Item>

								<Form.Item
									label=""
									name="message"
									rules={[
										{ required: true, message: "Please write the message!" },
									]}
								>
									<TextArea
										showCount
										maxLength={500}
										style={{
											height: 125,
											marginBottom: 12,
										}}
										type="text"
									/>
								</Form.Item>

								<Form.Item
									label="Service Evaluation"
									name="serviceRating"
									rules={[
										{ required: true, message: "Please rate the service!" },
									]}
								>
									<div className="radio-group">
										<label>
											<input type="radio" name="serviceRating" value="1" />
											<h2 className="emoji">😞</h2>
										</label>
										<label>
											<input type="radio" name="serviceRating" value="2" />
											<h2 className="emoji">😕</h2>
										</label>
										<label>
											<input type="radio" name="serviceRating" value="3" />
											<h2 className="emoji">😐</h2>
										</label>
										<label>
											<input type="radio" name="serviceRating" value="4" />
											<h2 className="emoji">🙂</h2>
										</label>
										<label>
											<input type="radio" name="serviceRating" value="5" />
											<h2 className="emoji">😊</h2>
										</label>
									</div>
								</Form.Item>

								<Form.Item>
									<Button
										style={{ width: "100%", marginTop: "20px" }}
										type="primary"
										htmlType="submit"
									>
										SEND MESSAGE
									</Button>
									<Button
										style={{
											width: "100%",
											background: "white",
											color: "Blue",
											marginTop: 30,
											marginBottom: 30,
										}}
										type="primary"
										htmlType="submit"
										onClick={() => navigate("/MySettings")}
									>
										CANCEL
									</Button>
								</Form.Item>
							</Form>
						</Card>
					</section>

					<section>
						<ResponsiveMenuDrawer
							profileType={profileType} // Pass profileType dynamically here
							handleMenuClick={handleMenuClick}
							handleHomeClick={handleHomeClick}
							handleStrategiesClick={handleStrategiesClick}
							handleExchangesClick={handleExchangesClick}
							handleAcademyClick={handleAcademyClick}
							handleAdmin={handleAdmin}
							handleSettingsClick={handleSettingsClick}
							handleSignOut={handleSignOut}
						/>
					</section>
				</div>
			</div>
		</Layout>
	);
};

export default MyContactEmail;
