import React, { useState, useEffect } from "react";
import { Drawer, Menu, FloatButton } from "antd";
import { useNavigate } from "react-router-dom";
import {
	Users, //admin
	Grid,
	BarChart2,
	TrendingUp,
	Database,
	Briefcase,
	Command,
	User,
	DollarSign,
	Clock,
	Settings,
	Lock,
	Trash,
	UserCheck,
	Activity,
	HelpCircle,
	Info,
	Bell,
	LifeBuoy,
	Mail,
	Phone,
	FileText,
	Shield,
	AlertCircle,
	Plus,
	Home,
	ArrowLeftRight,
	GraduationCap,
	LogOut,
	Shield as AdminIcon, // Admin Icon (you can replace this with your custom admin icon)
} from "lucide-react";

const ResponsiveMenuDrawer = ({
	handleMenuClick,
	handleHomeClick,
	handleStrategiesClick,
	handleExchangesClick,
	handleAcademyClick,
	handleSignOut,
	handleSettingsClick,
	// handleAdmin,
	profileType, // Get profileType prop here
}) => {
	const navigate = useNavigate();

	const version = "16.11.24";

	const items = [
		{
			key: "1",
			icon: <User />,
			label: <div>Account Details</div>,
			children: [
				{
					key: "/AccountDetails",
					icon: <User />,
					label: <span className="submenu-text">Personal Details</span>, // Add custom class
					onClick: () => navigate("/MyAccountDetails"), // Navigates on click
				},
				{
					key: "1-2",
					icon: <FileText />,
					label: <span className="submenu-text">Documentation</span>, // Add custom class
				},
			],
		},
		{ key: "2", icon: <DollarSign />, label: "Fees & Limits" },
		{ key: "3", icon: <Clock />, label: "History" },
		{
			key: "4",
			icon: <Settings />,
			label: "Security",
			onClick: () => navigate("/MySettings"),
		},
		{ key: "5", icon: <HelpCircle />, label: "Help Center" },
		{ key: "7", icon: <Bell />, label: "Notifications" },
		{
			key: "8",
			icon: <LifeBuoy />,
			label: "Support",
			children: [
				{
					key: "8-1",
					icon: <Mail />,
					label: <span className="submenu-text">Email</span>, // Add custom class
					onClick: () => navigate("/MyContactEmail"), // Navigates on click
				},
				{
					key: "8-2",
					icon: <Phone />,
					label: <span className="submenu-text">Phone Scheduler</span>, // Add custom class
					onClick: () => navigate("/MyContacts"), // Navigates on click
				},
			],
		},
		{
			key: "9",
			icon: <FileText />,
			label: "Legal",
			children: [
				{
					key: "9-1",
					icon: <FileText />,
					label: <span className="submenu-text">Terms of Use</span>, // Add custom class
					onClick: () => navigate("/TermsService"), // Navigates on click
				},
				{
					key: "9-2",
					icon: <Shield />,
					label: <span className="submenu-text">Privacy Policy</span>, // Add custom class
					onClick: () => navigate("/PrivacyPolicies"), // Navigates on click
				},
				{
					key: "9-3",
					icon: <AlertCircle />,
					label: <span className="submenu-text">Disclaimer</span>, // Add custom class
					onClick: () => navigate("/Disclaimer"), // Navigates on click
				},
			],
		},
		{
			key: "6",
			icon: <Info />,
			label: "Version",
			onClick: () => navigate("/MyAppInfo"),
		},
	];

	const adminSettingsItems = [
		{
			key: "1",
			icon: <Users />,
			label: <div>Users</div>,
			onClick: () => navigate("/Users"),
		},
		{
			key: "2",
			icon: <UserCheck />,
			label: <div>Agents</div>,
			onClick: () => navigate("/MyAgents"),
		},
		{
			key: "3",
			icon: <User />,
			label: <div>User Agents</div>,
			onClick: () => navigate("/UsersAgents"),
		},
		{
			key: "4",
			icon: <Grid />,
			label: <div>Categories</div>,
			onClick: () => navigate("/Categories"),
		},
		{
			key: "5",
			icon: <Shield />,
			label: <div>Risk Profile</div>,
			onClick: () => navigate("/InvestorRiskProfiles"),
		},
		{
			key: "6",
			icon: <BarChart2 />,
			label: <div>Asset Class</div>,
			onClick: () => navigate("/CategoryAssets"),
		},
		{
			key: "9",
			icon: <Briefcase />,
			label: <div>Products</div>,
			onClick: () => navigate("/MyProducts"),
		},
		{
			key: "8",
			icon: <Database />,
			label: <div>Exchanges</div>,
			onClick: () => navigate("/CurrencyAssets"),
		},
		{
			key: "13",
			icon: <DollarSign />,
			label: <div>Exchanges Quote</div>,
			onClick: () => navigate("/CurrencyRates"),
		},
		{
			key: "11",
			icon: <Activity />,
			label: <div>Crypto Risk</div>,
			onClick: () => navigate("/CryptoRisks"),
		},
		{
			key: "7",
			icon: <TrendingUp />,
			label: <div>Cryptocurrencies</div>,
			onClick: () => navigate("/CryptoAssets"),
		},
		{
			key: "10",
			icon: <DollarSign />,
			label: <div>Crypto Quote</div>,
			onClick: () => navigate("/CryptoPrices"),
		},
		{
			key: "12",
			icon: <Command />,
			label: <div>Smart-Advisor Crypto</div>,
			onClick: () => navigate("/CryptoSmarts"),
		},
	];

	const [drawerVisible, setDrawerVisible] = useState(false);
	const [currentMenu, setCurrentMenu] = useState(items); // Default to regular items

	const showDrawer = () => {
		setCurrentMenu(items);
		setDrawerVisible(true);
	};

	// const handleAdminClick = () => {
	// 	setCurrentMenu(adminSettingsItems);
	// 	setDrawerVisible(true);
	// };

	return (
		<>
			<Drawer
				title={"Settings"}
				placement="left"
				onClose={() => setDrawerVisible(false)}
				open={drawerVisible}
				width={250}
			>
				<Menu
					mode="inline"
					items={currentMenu}
					style={{ marginLeft: -30, width: "220px" }} // Adjust the value to your liking
				/>
			</Drawer>

			<FloatButton.Group
				trigger="click"
				type="primary"
				icon={<Plus size={18} style={{ color: "white" }} />}
				style={{
					position: "fixed",
					bottom: "20px", // Distance from the bottom of the screen
					right: "50px", // Distance from the right of the screen
					gap: "5px", // Gap between the buttons
				}}
			>
				{/* Regular Buttons for profileType = 0 */}
				{profileType === 0 && (
					<>
						<FloatButton
							icon={<Home size={18} />}
							tooltip="Home"
							onClick={handleHomeClick}
							className="float-button"
						/>
						<FloatButton
							icon={<TrendingUp size={18} />}
							tooltip="Strategies"
							onClick={handleStrategiesClick}
							className="float-button"
						/>
						<FloatButton
							icon={<ArrowLeftRight size={18} />}
							tooltip="Exchanges"
							onClick={handleExchangesClick}
							className="float-button"
						/>
						<FloatButton
							icon={<GraduationCap size={18} />}
							tooltip="Academy"
							onClick={handleAcademyClick}
							className="float-button"
						/>
						<FloatButton
							icon={<Settings size={18} />}
							tooltip="Settings"
							onClick={handleSettingsClick}
							className="float-button"
						/>
					</>
				)}

				{/* Admin Button for profileType = 9 */}
				{/* {profileType === 9 && (
					<FloatButton
						icon={<AdminIcon size={18} />}
						tooltip="Admin"
						onClick={handleAdminClick}
						className="float-button"
					/>
				)} */}

				<FloatButton
					icon={<LogOut size={18} />}
					tooltip="Sign Out"
					onClick={handleSignOut}
					className="float-button"
				/>
			</FloatButton.Group>
		</>
	);
};

export default ResponsiveMenuDrawer;
