import React, { useState, useEffect } from "react";
import {
	Input,
	Tabs,
	Form,
	Button,
	Radio,
	message,
	Tooltip,
	Row,
	Card,
	Table,
	Spin,
	Switch,
	Col,
	Typography,
	Dropdown,
	Menu,
	Popconfirm,
	Layout,
	Modal,
	Select,
} from "antd";
import Binance from "./../components/Assets/binance.svg";
import Navbar from "../components/Layout/Navbar";
import { useNavigate } from "react-router-dom";
import { format, subDays } from "date-fns";
import axios from "axios";
import {
	ArrowDownOutlined,
	ArrowLeftOutlined,
	ArrowUpOutlined,
	CheckCircleFilled,
	ExclamationCircleFilled,
	HddOutlined,
	MinusOutlined,
	PieChartOutlined,
	PlusOutlined,
	ReloadOutlined,
	SettingOutlined,
	StockOutlined,
	TableOutlined,
	UnorderedListOutlined,
	UploadOutlined,
} from "@ant-design/icons";

import {
	BadgePercent,
	Star,
	Crown,
	Gem,
	ArrowRight,
	Database,
	MoreHorizontal,
	Edit,
	FileText,
	RefreshCcw,
	Trash2,
	RefreshCw,
	PlusCircleIcon,
	TrendingUp,
} from "lucide-react";

import moment from "moment";
import Chart from "react-apexcharts";
import ResponsiveMenuDrawer from "../components/ResponsiveMenuDrawer.js"; // Adjust the path

const MyInvestments = () => {
	const { Title, Text } = Typography;

	const { Option } = Select; // Extract Option from Select

	const user = JSON.parse(localStorage.getItem("user"));
	const profileType = user.profileType;
	const user_currency = user.currency;

	const currencySymbol = user_currency === "USD" ? "$" : "€";

	const investor = JSON.parse(localStorage.getItem("investorProfile"));
	// console.log(investor)

	const { Meta } = Card;
	const { TabPane } = Tabs;

	const [showModal, setShowModal] = useState(false);

	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	const [allExchangePortfolio, setAllExchangePortfolio] = useState([]);

	const [activeTab, setActiveTab] = useState("Aggregate");

	const handleTabChange = (key) => {
		setActiveTab(key);
	};

	const [brokerDetails, setBrokerDetails] = useState(null);

	const [allInvestmentStats, setAllInvestmentStats] = useState([]);
	const [allStats, setAllStats] = useState([]);
	const [allBalanceStats, setAllBalanceStats] = useState([]);
	const [cashExchanges, setCashExchanges] = useState([]);

	//Fields from Graphs to View
	const [allInvestmentTypeWeights, setAllInvestmentTypeWeights] =
		useState(null);
	const [
		allAllInvestmentIsProtectedWeights,
		setAllInvestmentIsProtectedWeights,
	] = useState({});

	const [distinctExchanges, setDistinctExchanges] = useState([]);

	// Helper function to filter the data by a given range
	const filterDataByPeriod = (data, days) => {
		const endDate = new Date();
		const startDate = subDays(endDate, days); // Calculate the start date

		return data.filter((item) => {
			const itemDate = new Date(item._id.dateExchangePerformance);
			return itemDate >= startDate && itemDate <= endDate; // Include only the items within the range
		});
	};

	// Calculate the max value
	const getMaxValue = (data) => {
		return Math.max(...data.map((item) => item.today_value)); // Return the highest value
	};

	// Helper function to get min value
	const getMinValue = (data) =>
		Math.min(...data.map((item) => item.today_value));

	// Helper function to calculate cumulative return
	const getCumulativeReturn = (data) => {
		const initialValue = data[0]?.today_value || 0;
		const finalValue = data[data.length - 1]?.today_value || 0;
		return initialValue
			? ((finalValue - initialValue) / initialValue) * 100
			: 0;
	};

	/*************************************************************************** */

	useEffect(() => {
		const getallStats = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/portfolio-exchanges/get-all-exchange",
					{
						userid: user._id,
						// archive: false,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				// setAllStats(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				// //message.error('Fetch Issue With account');
			}
		};

		// Clean up the interval when the component unmounts
		return () => getallStats();
	}, []);

	useEffect(() => {
		const getAllCashExchanges = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/cash-exchanges/get-cash-exchange",
					{
						userid: user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setCashExchanges(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
			}
		};

		getAllCashExchanges();
	}, []);

	const CountExchanges = cashExchanges.length;

	/*************************************************************************** */
	const [weightAssetSubject, setWeightAssetSubject] = useState([]);
	const [weightAssetMarks, setWeightAssetMarks] = useState([]);

	useEffect(() => {
		const sSubject = [];
		const sMarks = [];
		const getAllAssetWeights = async (values) => {
			try {
				setLoading(true);
				const user = JSON.parse(localStorage.getItem("user"));
				const res = await axios.post(
					"/portfolio-exchanges/get-asset-exchange-account",
					{
						userid: user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllStats(res.data);
				// console.log(res.data);

				for (let i = 0; i < res.data.length; i++) {
					sSubject.push(res.data[i].cryptoAssetName);
					sMarks.push(res.data[i].amount);
				}
				setWeightAssetSubject(sSubject);
				setWeightAssetMarks(sMarks);
			} catch (error) {
				setLoading(false);
				// message.error('Fetch Issue With Sector');
				message.error("You are not Admin User");
			}
		};
		getAllAssetWeights();
	}, []);
	/*************************************************************************** */

	// console.log("user.investorProfile: ",user.investorProfile);
	// console.log("investor: ",investor);

	let range = { min: 1, max: 999999999 };
	let delta = range.max - range.min;

	const codeSquare = Math.round(range.min + Math.random() * delta);

	//NEW EXCHANGE
	const handleSubmitNewExchange = (values) => {
		// console.log('Submitted Email:', values);
		try {
			const user = JSON.parse(localStorage.getItem("user"));

			setLoading(true);

			const result = axios.post(
				"/exchanges/add-exchange",
				{
					...values,
					brokerName: "Binance",
					codeSquare: codeSquare,
					assetType: "Cryptocurrencies",
					quantity: 1,
					userid: user._id,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);

			setLoading(false);
			// navigate("/ProcessingExchanges", {
			// 	state: {
			// 		exchangeName: values.investmentName,
			// 		processing: "Portfolio em criação",
			// 		navigateLink: "/MyInvestments",
			// 		codeSquare: codeSquare,
			// 	},
			// });
		} catch (error) {
			setLoading(false);
		}
	};

	const isNotEmptyArray =
		Array.isArray(distinctExchanges) && distinctExchanges.length > 0;
	const isBinanceVisible = distinctExchanges && distinctExchanges[0];

	// NOVO

	const [isVisible, setIsVisible] = useState(false);
	const [showDailyReturns, setShowDailyReturns] = useState(false);
	const [isUSD, setIsUSD] = useState(true);
	const [exchangeRate, setExchangeRate] = useState({ usd: 1, eur: 1 });

	const [selectedPeriod, setSelectedPeriod] = useState("1mo");
	const [customDateRange, setCustomDateRange] = useState([null, null]);

	const currencyRate = user.currency;

	useEffect(() => {
		const getallInvestmentStats = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/exchange-balances/get-exchange-balance",
					{
						userid: user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllBalanceStats(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				// //message.error('Fetch Issue With account');
			}
		};

		getallInvestmentStats();
	}, []);

	//Analytics
	const currentBalance = allBalanceStats.reduce(
		(acc, transaction) => acc + transaction.today_value,
		0
	);

	const dailyReturn = allBalanceStats.reduce(
		(acc, transaction) => acc + transaction.difTodayValue,
		0
	);

	const dailyReturnColor = dailyReturn > 0 ? "green" : "red";

	const invested = currentBalance - dailyReturn;
	const dailyReturnPercentage = ((dailyReturn / invested) * 100).toFixed(2);

	useEffect(() => {
		const getallInvestmentStats = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/portfolio-exchanges/get-cash-exchange",
					{
						userid: user._id,
						archive: false,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllInvestmentStats(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				// //message.error('Fetch Issue With account');
			}
		};

		// Clean up the interval when the component unmounts
		return () => getallInvestmentStats();
	}, []);

	//Analytics
	const cash = allInvestmentStats.reduce(
		(acc, transaction) => acc + transaction.cash,
		0
	);

	// Toggle visibility handler
	const toggleVisibility = () => {
		setIsVisible(!isVisible);
	};

	// Toggle return type handler
	const toggleReturnType = (checked) => {
		setShowDailyReturns(checked);
	};

	// Toggle currency type handler
	const toggleCurrency = (checked) => {
		setIsUSD(checked);
	};

	const convertToCurrency = (amount) => {
		const convertedAmount = isUSD ? amount : amount * exchangeRate.usd;
		return new Intl.NumberFormat("us-US", {
			style: "currency",
			currency: isUSD ? "USD" : currencyRate,
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(convertedAmount);
	};

	// Fetch Balance Portfolio
	useEffect(() => {
		const getAllSavingPerformances = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/exchange-performances/get-exchange-performance",
					{
						userid: user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setAllExchangePortfolio(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				//message.error('Fetch Issue With account');
			}
		};

		getAllSavingPerformances();
	}, []);

	// Data for different periods
	const periodData = {
		"7d": filterDataByPeriod(allExchangePortfolio, 7),
		"1mo": filterDataByPeriod(allExchangePortfolio, 30),
		"3mo": filterDataByPeriod(allExchangePortfolio, 90),
		"1yr": filterDataByPeriod(allExchangePortfolio, 365),
		allTime: allExchangePortfolio, // For MAX, use all-time data
	};

	// Get the data for the selected period
	const dataToUse = periodData[selectedPeriod];

	// Get the max value for the selected period
	const maxValue = getMaxValue(dataToUse);

	// Get the min value for the selected period
	const minValue = getMinValue(dataToUse);

	// Get the cumulative return for the selected period
	const cumulativeReturn = getCumulativeReturn(dataToUse);

	// Calculate the midpoint of minValue and maxValue
	const midValue = (maxValue + minValue) / 2;

	// Prepare chart data
	const chartData = dataToUse.map((item) => ({
		x: item._id.dateExchangePerformance,
		y: item.today_value,
	}));

	const options = {
		chart: {
			id: "portfolio-performance-chart",
			type: "area",
			toolbar: { show: false },
		},
		xaxis: {
			type: "datetime",
		},
		yaxis: {
			labels: {
				formatter: (value) => `$${value.toLocaleString()}`,
			},
		},
		stroke: {
			curve: "smooth",
			width: 2,
		},
		dataLabels: {
			enabled: false,
		},
		fill: {
			opacity: 0.4,
		},
		tooltip: {
			x: {
				formatter: (value) => new Date(value).toLocaleDateString(),
			},
		},
		annotations: {
			enabled: false,
			yaxis: [
				{
					y: maxValue,
					borderColor: "#FF4560",
					label: {
						text: `Max: $${maxValue.toFixed(2).toLocaleString()}`,
						style: {
							color: "#fff",
							background: "#FF4560",
						},
					},
				},
				{
					y: minValue,
					borderColor: "#775DD0",
					label: {
						text: `Min: $${minValue.toFixed(2).toLocaleString()}`,
						style: {
							color: "#fff",
							background: "#775DD0",
						},
					},
				},
				{
					y: midValue, // Positioning Cumulative Return at the midpoint
					borderColor: "#00E396",
					label: {
						text: `Cumulative Return: ${cumulativeReturn.toFixed(2)}%`,
						style: {
							color: "#fff",
							background: "#00E396",
						},
					},
				},
			],
		},
	};

	//CARDS
	const portfolioDataExchange = [
		{
			id: "bitcoin",
			symbol: "BINANCE 1",
			image:
				"https://e7.pngegg.com/pngimages/429/120/png-clipart-retirement-others-child-food-thumbnail.png",
			price_change_percentage_24h: 4.818,
			current_price: 1051.62,
			updated_at: new Date(), // Date object for the last update time
			available_money: 1500, // New available money field
		},
		{
			id: "ethereum",
			symbol: "BINANCE 2",
			image:
				"https://e7.pngegg.com/pngimages/429/120/png-clipart-retirement-others-child-food-thumbnail.png",
			price_change_percentage_24h: 4.818,
			current_price: 2200.37,
			updated_at: new Date(), // Date object for the last update time
			available_money: 3200, // New available money field
		},
	];

	const [iconColor, setIconColor] = useState("#595959"); // State to manage the icon color

	const handleBack = () => {
		navigate("/");
	};

	// Mouse enter handler to change icon color on hover
	const handleMouseEnter = () => {
		setIconColor("#1890ff"); // Change color on hover
	};

	// Mouse leave handler to reset icon color when mouse leaves
	const handleMouseLeave = () => {
		setIconColor("#595959"); // Reset color on mouse leave
	};

	const handleDelete = () => {
		// Simulate account deletion logic (e.g., API call)
		message.success("Your account has been deleted successfully!");
	};

	const handleHelpNavigation = () => {
		navigate("/HelpBinanceAccount"); // Navigate to the specified route
	};

	// Function to format date
	const formatDate = (date) => {
		return `${date.getDate()}/${
			date.getMonth() + 1
		}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
	};

	// Fetch exchange rates from CoinGecko API
	const fetchExchangeRate = async () => {
		const user = JSON.parse(localStorage.getItem("user"));
		try {
			const response = await fetch(
				"https://api.coingecko.com/api/v3/simple/price?ids=" +
					user.currency.toLowerCase() +
					",usd&vs_currencies=" +
					user.currency.toLowerCase() +
					",usd"
			);
			const data = await response.json();
			setExchangeRate({
				usd: data.usd[user.currency.toLowerCase()], // USD to EUR rate
				eur: 1, // Base currency EUR
			});
			console.log(data);
		} catch (error) {
			console.error("Error fetching exchange rate:", error);
		}
	};

	// Call the fetch function when the component mounts
	useEffect(() => {
		fetchExchangeRate();
	}, []);

	const [form] = Form.useForm();

	// State management
	const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

	const handleNewExchange = () => {
		setIsModalOpen(true); // Open modal
	};

	const handleCancel = () => {
		setIsModalOpen(false); // Close modal
		form.resetFields(); // Optionally reset form fields on close
	};

	// Dynamic Button Rendering
	const renderButton = () => {
		return (
			<Button
				className="button-app"
				style={{
					backgroundColor: "#9133EF",
					borderColor: "#9133EF",
					color: "#fff",
				}}
				size="large"
				onClick={handleNewExchange}
				icon={
					<RefreshCcw size={24} style={{ marginRight: "8px", color: "#fff" }} />
				} // Add icon
			>
				New Exchange
			</Button>
		);
	};

	//Responsive Button Rendering
	// Define a function to handle button clicks and log the corresponding action
	const handleClick = (action) => {
		console.log(`${action} button clicked!`);
	};

	// Define a function to handle button clicks and log the corresponding action
	const handleMenuClick = (e) => console.log("Menu item clicked:", e.key);

	const handleHomeClick = () => {
		navigate("/");
	};

	const handleStrategiesClick = () => {
		navigate("/Strategies");
	};

	const handleExchangesClick = () => {
		navigate("/MyInvestments");
	};

	const handleAcademyClick = () => {
		navigate("/FinanceLiteracy");
	};

	const handleAdmin = () => {
		navigate("/ExplorerSettings");
	};

	const handleSettingsClick = () => {
		navigate("/MySettings");
	};

	const handleSignOut = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("investorProfile");
		navigate("/login");
	};

	return (
		<Layout>
			<Navbar />
			<div className="container">
				{loading && <Spin />}
				<div>
					{/* Fixed back icon */}
					<ArrowLeftOutlined
						onClick={handleBack} // Navigate back to the previous page
						style={{
							top: 10, // Distance from the top
							fontSize: "24px", // Icon size
							cursor: "pointer", // Change the cursor to pointer on hover
							marginBottom: "10px",
							marginTop: "10px",
						}}
					/>

					<section>
						<Card className="card-financial-summary">
							<Row
								justify="space-between"
								align="middle"
								className="daily-returns-toggle"
							>
								<Title level={4} className="section-title">
									Total Market Values
								</Title>
								<Switch
									checked={isUSD}
									onChange={toggleCurrency}
									checkedChildren="USD"
									unCheckedChildren={currencyRate}
								/>
							</Row>
							<Row justify="center" className="balance-section">
								<Col onClick={toggleVisibility} className="balance-text">
									<h1>
										{isVisible
											? convertToCurrency(currentBalance)
											: "Tap to see balance"}
									</h1>
								</Col>
							</Row>
							<Row justify="space-between" className="summary-row">
								<Col>Daily Returns</Col>
								<Col>Cash Available</Col>
							</Row>
							<Row justify="space-between" className="summary-row">
								<Col style={{ textAlign: "left" }}>
									<Text
										style={{
											color: isVisible ? dailyReturnColor : "#999",
											fontWeight: "bold",
										}}
									>
										{isVisible
											? dailyReturn && dailyReturnPercentage
												? `${convertToCurrency(
														dailyReturn
												  )} (${dailyReturnPercentage}%)`
												: "0.00 (0.00%)"
											: "****"}
									</Text>
								</Col>
								<Col>{isVisible ? convertToCurrency(cash) : "****"}</Col>
							</Row>
						</Card>
					</section>

					<section>
						<Card>
							<Chart
								options={options}
								series={[{ name: "Portfolio Value", data: chartData }]}
								type="area"
								height={350}
							/>

							<div className="tabs-container">
								<Tabs
									defaultActiveKey="1mo"
									onChange={(key) => setSelectedPeriod(key)}
									items={[
										{ label: "7d", key: "7d" },
										{ label: "1M", key: "1mo" },
										{ label: "3M", key: "3mo" },
										{ label: "1Y", key: "1yr" },
										{ label: "MAX", key: "allTime" },
									]}
								/>
							</div>
						</Card>
					</section>

					<section>
						{allInvestmentStats.map((portfolio) => {
							// Dynamically create the menu for each portfolio
							const menu = (
								<Menu className="custom-menu">
									<Menu.Item
										key="edit"
										onClick={() => {
											navigate("/EditExchanges", {
												state: {
													userID: portfolio.userid,
													exchangeID: portfolio.exchangeName._id,
													exchangeName: portfolio.exchangeName.investmentName,
													exchangeDescription:
														portfolio.exchangeName.exchangeDescription,
													brokerName: portfolio.exchangeName.brokerName,
													currency: portfolio.exchangeName.currency,
													frequency: portfolio.exchangeName.frequency,
													goalName: portfolio.exchangeName.goalName,
													codeSquare: portfolio.exchangeName.codeSquare,
													status: portfolio.exchangeName.status,
													apiKey: portfolio.exchangeName.apiKey,
													keySecret: portfolio.exchangeName.keySecret,
												},
											});
										}}
										className="menu-item edit-item"
									>
										<Edit className="menu-icon" />
										Edit
									</Menu.Item>
									<Menu.Item
										key="Portfolio"
										onClick={() => {
											navigate("/PortfolioExchanges", {
												state: {
													userID: portfolio.userid,
													exchangeID: portfolio.exchangeName._id,
													exchangeName: portfolio.exchangeName.investmentName,
													currency: portfolio.exchangeName.currency,
												},
											});
										}}
										className="menu-item detail-item"
									>
										<TrendingUp className="menu-icon" />
										Detail
									</Menu.Item>
									<Menu.Item
										key="refresh"
										onClick={() => {
											// console.log(opts);
											navigate("/ProcessingRefreshExchange", {
												state: {
													exchangeID: portfolio.exchangeName._id,
													exchangeName: portfolio.exchangeName.investmentName,
													processing: "Refresh Exchange",
												},
											});
										}}
										className="menu-item refresh-item"
									>
										<RefreshCcw className="menu-icon" />
										Refresh
									</Menu.Item>
									{/* <Menu.Item key="delete" className="menu-item delete-item">
									<Popconfirm
										title="Are you sure you want to delete this?"
										onConfirm={() => handleDelete(portfolio)}
										okText="Yes"
										cancelText="No"
									>
										<span>
											<Trash2 className="menu-icon" />
											Delete
										</span>
									</Popconfirm>
								</Menu.Item> */}
								</Menu>
							);

							return (
								<Card
									hoverable
									bordered={false}
									className="coin-card-content"
									key={portfolio.id}
									style={{ marginBottom: "20px" }}
								>
									<Meta
										title={
											<div
												className="coin-info"
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "space-between",
												}}
											>
												<div style={{ display: "flex", alignItems: "center" }}>
													<img
														alt={Binance}
														src={Binance}
														className="coin-logo-exchange"
													/>
													<div
														className="coin-details"
														style={{ marginLeft: 10 }}
													>
														<span className="coin-symbol">
															{portfolio.exchangeName.investmentName.toUpperCase()}
														</span>
														<span className="coin-name current-price">
															Portfolio Value:&nbsp;
															{convertToCurrency(
																portfolio.portfolio_exchange_value
															).toLocaleString()}
														</span>
														<span className="coin-name">
															Available Money:&nbsp;
															{convertToCurrency(
																portfolio.cash
															).toLocaleString()}
														</span>
														<span className="coin-update-time">
															Last Update: {portfolio.date_refreshed}
														</span>
													</div>
												</div>
												<Dropdown overlay={menu} trigger={["click"]}>
													<MoreHorizontal
														style={{
															fontSize: 8,
															cursor: "pointer",
															transform: "rotate(90deg)", // Rotate icon vertically
															color: iconColor,
															transition: "all 0.3s ease",
														}}
														onMouseEnter={handleMouseEnter}
														onMouseLeave={handleMouseLeave}
													/>
												</Dropdown>
											</div>
										}
									/>
								</Card>
							);
						})}
					</section>

					<section>
						<Row justify="center" style={{ margin: "20px 0" }}>
							{renderButton()}

							{/* Modal for New Exchange */}
							<Modal
								title={
									<div style={{ textAlign: "center", width: "100%" }}>
										Connect New Exchange
									</div>
								}
								open={isModalOpen}
								onCancel={handleCancel}
								centered
								footer={null} // Remove default modal footer
							>
								{/* Centered Binance Logo */}
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										marginBottom: "16px",
									}}
								>
									<img
										src="https://cdn.worldvectorlogo.com/logos/binance.svg"
										alt="Binance Logo"
										style={{ width: "120px", height: "120px" }}
									/>
								</div>

								{/* Form */}
								<Form
									form={form}
									layout="vertical"
									onFinish={handleSubmitNewExchange} // Form submission handler
								>
									{/* Select Dropdown */}
									<Form.Item
										label="Exchange"
										name="exchange"
										initialValue="Binance"
										rules={[
											{ required: true, message: "Please select an exchange!" },
										]}
									>
										<Select>
											<Option value="Binance">Binance</Option>
										</Select>
									</Form.Item>

									{/* Connection Name */}
									<Form.Item label="Connection name" name="investmentName">
										<Input placeholder="Connection Name" type="text" />
									</Form.Item>

									{/* API Key Input */}
									<Form.Item
										label="API Key"
										name="apiKey"
										rules={[
											{ required: true, message: "Please input the API key!" },
										]}
									>
										<Input placeholder="API Key" type="text" />
									</Form.Item>

									{/* Secret Key Input */}
									<Form.Item
										label="Secret Key"
										name="keySecret"
										rules={[
											{
												required: true,
												message: "Please input the secret key!",
											},
										]}
									>
										<Input placeholder="Secret Key" type="text" />
									</Form.Item>

									{/* Help Link */}
									<p style={{ marginBottom: "16px", textAlign: "center" }}>
										Don&apos;t have a Binance account?{" "}
										<a
											href="https://www.binance.com/activity/referral-entry/CPA/together-v4?hl=en&ref=CPA_00FTGI1106"
											target="_blank"
											rel="noopener noreferrer"
										>
											Create it now
										</a>
									</p>

									{/* Connect Button */}
									<Form.Item>
										<Button className="float-button" htmlType="submit" block>
											Connect
										</Button>
									</Form.Item>

									{/* Informational Cards */}
									<div style={{ marginTop: "20px" }}>
										<Card
											style={{
												marginBottom: "10px",
												padding: "12px", // Reduce padding for smaller appearance
												fontSize: "14px", // Smaller font size for text
											}}
											bodyStyle={{ padding: "10px" }} // Further control over the internal padding
										>
											<h4 style={{ marginBottom: "8px" }}>
												🔒 Secure Connection
											</h4>
											<p style={{ margin: 0 }}>
												All data is exchanged over a secure, encrypted
												connection to protect your information.
											</p>
										</Card>
										<Card
											style={{
												padding: "12px",
												fontSize: "14px",
											}}
											bodyStyle={{ padding: "10px" }}
										>
											<h4 style={{ marginBottom: "8px" }}>
												🔧 User-Controlled Permissions
											</h4>
											<p style={{ margin: 0 }}>
												You are in control. You can review and revoke access at
												any time from your account settings.
											</p>
										</Card>
									</div>
									<div style={{ marginTop: "20px" }}>
										<Card
											style={{
												marginBottom: "10px",
												padding: "12px",
												fontSize: "14px",
											}}
											bodyStyle={{ padding: "10px" }}
										>
											<h4 style={{ marginBottom: "8px" }}>
												📝 Step-by-Step Instructions
											</h4>
											<ol style={{ paddingLeft: "20px", margin: "0" }}>
												<li style={{ marginBottom: "8px" }}>
													Login to your Binance account on your computer.
												</li>
												<li style={{ marginBottom: "8px" }}>
													Select <strong>Account</strong> from the left side
													menu <strong>API Management</strong>.
												</li>
												<li style={{ marginBottom: "8px" }}>
													Click the <strong>Create API</strong> button and
													choose <strong>System generated API key</strong> type.
												</li>
												<li style={{ marginBottom: "8px" }}>
													In the <strong>Label API Key</strong> field, type what
													you want to call it, e.g., <em>BitAdvisor Binance</em>
													, then click <strong>Next</strong>.
												</li>
												<li style={{ marginBottom: "8px" }}>
													Input your{" "}
													<strong>Google Authentication Code (2FA)</strong> for
													Binance and <strong>Email Verification Code</strong>.
												</li>
												<li style={{ marginBottom: "8px" }}>
													Next, copy the <strong>API Key</strong> and{" "}
													<strong>Secret Key</strong> to BitAdvisor.
												</li>
											</ol>
										</Card>
										{/* Help Link */}
										<p style={{ marginBottom: "16px", textAlign: "center" }}>
											Do you need more help?{" "}
											<span
												onClick={handleHelpNavigation}
												style={{
													color: "#1890ff",
													cursor: "pointer",
													textDecoration: "underline",
												}}
											>
												Click here
											</span>
										</p>
									</div>
								</Form>
							</Modal>
						</Row>
					</section>

					<section>
						<ResponsiveMenuDrawer
							profileType={profileType} // Pass profileType dynamically here
							handleMenuClick={handleMenuClick}
							handleHomeClick={handleHomeClick}
							handleStrategiesClick={handleStrategiesClick}
							handleExchangesClick={handleExchangesClick}
							handleAcademyClick={handleAcademyClick}
							handleAdmin={handleAdmin}
							handleSettingsClick={handleSettingsClick}
							handleSignOut={handleSignOut}
						/>
					</section>
				</div>
			</div>
		</Layout>
	);
};

export default MyInvestments;
