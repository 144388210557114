import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Divider, Table, Card, Typography, Layout, Form, Button } from "antd";
import ResponsiveMenuDrawer from "../components/ResponsiveMenuDrawer.js"; // Adjust the path
import { ArrowLeftOutlined } from "@ant-design/icons";
import Chart from "react-apexcharts";
import axios from "axios";

const StrategyDetails = () => {
	const { Title, Text } = Typography;
	const { Content } = Layout;

	const navigate = useNavigate();
	const location = useLocation();
	const selectedStrategy = location.state;

	const productNameUpdate = selectedStrategy.selectedStrategy;
	const idStrategy = selectedStrategy.idStrategy;
	console.log("idStrategy:", idStrategy);

	const [loginUser, setLoginUser] = useState("");

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user"));
		if (user) {
			setLoginUser(user);
		}
	}, []);

	const profileType = loginUser.profileType;

	const [initialInvestment, setInitialInvestment] = useState(1000);
	const [monthlyInvestment, setMonthlyInvestment] = useState(100);
	const [years, setYears] = useState(5);
	const [selectedStrategyIndex, setSelectedStrategyIndex] = useState(0);

	const [loading, setLoading] = useState(false);
	const [strategyData, setAllStrategies] = useState([]);

	const handleBack = () => {
		navigate("/Strategies");
	};

	// Define a function to handle button clicks and log the corresponding action
	const handleClick = (action) => {
		console.log(`${action} button clicked!`);
	};

	// Define a function to handle button clicks and log the corresponding action
	const handleMenuClick = (e) => console.log("Menu item clicked:", e.key);

	const handleHomeClick = () => {
		navigate("/");
	};

	const handleStrategiesClick = () => {
		navigate("/Strategies");
	};

	const handleExchangesClick = () => {
		navigate("/MyInvestments");
	};

	const handleAcademyClick = () => {
		navigate("/FinanceLiteracy");
	};

	const handleAdmin = () => {
		navigate("/ExplorerSettings");
	};

	const handleSettingsClick = () => {
		navigate("/MySettings");
	};

	const handleSignOut = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("investorProfile");
		navigate("/login");
	};

	// CHART

	const [allSavingPerformances, setSavingPerformances] = useState([]);

	// Fetch Balance Portfolio
	useEffect(() => {
		const getAllSavingPerformances = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/productPerformances/get-product-performance",
					{
						userid: user._id,
						productName: idStrategy,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);
				setSavingPerformances(res.data);
				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				//message.error('Fetch Issue With account');
			}
		};

		getAllSavingPerformances();
	}, []);

	const dataPerformance = [
		{ datePerformance: "2022-01-01", Return: 10 }, // January 2022 performance
		{ datePerformance: "2022-02-01", Return: 12 },
		{ datePerformance: "2022-03-01", Return: 14 },
		{ datePerformance: "2022-04-01", Return: 18 },
		{ datePerformance: "2022-05-01", Return: 20 },
		{ datePerformance: "2022-06-01", Return: 22 },
		{ datePerformance: "2022-07-01", Return: 25 },
		{ datePerformance: "2022-08-01", Return: 28 },
		{ datePerformance: "2022-09-01", Return: 30 },
		{ datePerformance: "2022-10-01", Return: 32 },
		{ datePerformance: "2022-11-01", Return: 34 },
		{ datePerformance: "2022-12-01", Return: 36 },
	];

	//TABLE
	const [allTableYearSavingPerformances, setTableYearPerformances] = useState(
		[]
	);

	const [allTableMonthSavingPerformances, setTableMonthPerformances] = useState(
		[]
	);

	// Fetch Balance Portfolio
	useEffect(() => {
		const getAllYearSavingPerformances = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/productYearPerformances/get-product-year-performance",
					{
						userid: user._id,
						productName: idStrategy,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);

				const res2 = await axios.post(
					"/productMonthPerformances/get-product-month-performance",
					{
						userid: user._id,
						productName: idStrategy,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);

				setLoading(false);
				setTableYearPerformances(res.data);
				setTableMonthPerformances(res2.data);

				console.log("setTableYearPerformances:", res.data);
				console.log("setTableMonthPerformances:", res2.data);
			} catch (error) {
				setLoading(false);
				//message.error('Fetch Issue With account');
			}
		};

		getAllYearSavingPerformances();
	}, []);

	const data = [
		{
			_id: "67694d7b93f3ac9dfbfbc8ff",
			Year: "Last 12 months",
			EOY: 0.3017,
			productName: "665212eabdc9cabf5f972752",
			products_name: "Kingdom",
		},
		{
			_id: "67694d7b93f3ac9dfbfbc900",
			Year: "Year to date",
			EOY: 0.308,
			productName: "665212eabdc9cabf5f972752",
			products_name: "Kingdom",
		},
		{
			_id: "67694d7b93f3ac9dfbfbc901",
			Year: "2023",
			EOY: 0.1137,
			productName: "665212eabdc9cabf5f972752",
			products_name: "Kingdom",
		},
		{
			_id: "67694d7b93f3ac9dfbfbc902",
			Year: "2022",
			EOY: -0.0077,
			productName: "665212eabdc9cabf5f972752",
			products_name: "Kingdom",
		},
		{
			_id: "67694d7b93f3ac9dfbfbc903",
			Year: "2021",
			EOY: -0.0502,
			productName: "665212eabdc9cabf5f972752",
			products_name: "Kingdom",
		},
	];

	// Example monthlyReturnsData
	const monthlyReturnsData2 = [
		{ Year: 2021, month: "Jan", return: 3 },
		{ Year: 2021, month: "Feb", return: -1 },
		{ Year: 2021, month: "Mar", return: 6 },
		{ Year: 2021, month: "Apr", return: -4 },
		{ Year: 2021, month: "May", return: 5 },
		{ Year: 2021, month: "Jun", return: 4 },
		{ Year: 2021, month: "Jul", return: 2 },
		{ Year: 2021, month: "Aug", return: -2 },
		{ Year: 2021, month: "Sep", return: 3 },
		{ Year: 2021, month: "Oct", return: 1 },
		{ Year: 2021, month: "Nov", return: 2 },
		{ Year: 2021, month: "Dec", return: 4 },

		{ Year: 2022, month: "Jan", return: 1 },
		{ Year: 2022, month: "Feb", return: 2 },
		{ Year: 2022, month: "Mar", return: 7 },
		{ Year: 2022, month: "Apr", return: -2 },
		{ Year: 2022, month: "May", return: 6 },
		{ Year: 2022, month: "Jun", return: 5 },
		{ Year: 2022, month: "Jul", return: 8 },
		{ Year: 2022, month: "Aug", return: 3 },
		{ Year: 2022, month: "Sep", return: 2 },
		{ Year: 2022, month: "Oct", return: 4 },
		{ Year: 2022, month: "Nov", return: 5 },
		{ Year: 2022, month: "Dec", return: -1 },

		{ Year: 2023, month: "Jan", return: -2 },
		{ Year: 2023, month: "Jan", return: -2 },
		{ Year: 2023, month: "Feb", return: 1 },
		{ Year: 2023, month: "Mar", return: 5 },
		{ Year: 2023, month: "Apr", return: -3 },
		{ Year: 2023, month: "May", return: 2 },
		{ Year: 2023, month: "Jun", return: -1 },
		{ Year: 2023, month: "Jul", return: 3 },
		{ Year: 2023, month: "Aug", return: 7 },
		{ Year: 2023, month: "Sep", return: 4 },
		{ Year: 2023, month: "Oct", return: -1 },
		{ Year: 2023, month: "Nov", return: 6 },
		{ Year: 2023, month: "Dec", return: -2 },
	];

	const columns = [
		// {
		//   title: 'ID',
		//   dataIndex: '_id',
		//   key: '_id',
		// },
		{
			title: "Year",
			dataIndex: "Year",
			key: "Year",
			defaultSortOrder: "descend",
		},
		{
			title: "EOY",
			dataIndex: "EOY",
			key: "EOY",
			render: (text) => `${(text * 100).toFixed(2)}%`,
		},
		// {
		//   title: 'Product ID',
		//   dataIndex: 'productName',
		//   key: 'productName',
		// },
		// {
		//   title: 'Product Name',
		//   dataIndex: 'products_name',
		//   key: 'products_name',
		// },
	];

	const chartOptionsBar = {
		chart: {
			id: "performance-chart",
			toolbar: {
				show: false,
			},
		},
		yaxis: {
			// title: { text: "Investment ($)" },
			labels: {
				style: { fontSize: "12px" },
				formatter: function (value) {
					return parseInt(value, 10); // Convert to integer
				},
			},
		},
		xaxis: {
			categories: allSavingPerformances
				.sort(
					(a, b) => new Date(a.datePerformance) - new Date(b.datePerformance)
				) // Sort by datePerformance
				.map((data) => {
					const date = new Date(data.datePerformance); // Parse the date
					const year = date.getFullYear();
					const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure month is 2 digits
					// Show only specific labels with the year
					return month === "01" ? `${year}` : ""; // Show year only for January
				}), // Dates on the x-axis
			title: {
				// text: "Date",
				// style: {
				// 	fontSize: "16px", // Default font size
				// },
			},
			labels: {
				style: {
					fontSize: "12px", // Default label font size
				},
			},
			tickPlacement: "on",
			// Make the font responsive by using a media query style approach
			style: {
				fontSize: "14px", // Default font size for ticks and labels
			},
		},

		// title: {
		// 	text: "Performance",
		// 	align: "center",
		// },
		stroke: {
			curve: "smooth",
		},
		// markers: {
		//   size: 4,
		// },
		tooltip: {
			y: {
				formatter: (val) => `${val.toFixed(2)}%`,
			},
		},
		responsive: [
			{
				breakpoint: 2000, // For tablet or smaller devices
				options: {
					xaxis: {
						title: {
							style: {
								fontSize: "16px", // Adjust font size on smaller screens
							},
						},
						labels: {
							style: {
								fontSize: "12px", // Adjust label font size on smaller screens
							},
						},
					},
				},
			},
			{
				breakpoint: 768, // For tablet or smaller devices
				options: {
					xaxis: {
						title: {
							style: {
								fontSize: "14px", // Adjust font size on smaller screens
							},
						},
						labels: {
							style: {
								fontSize: "10px", // Adjust label font size on smaller screens
							},
						},
					},
				},
			},
			{
				breakpoint: 480, // For mobile devices
				options: {
					xaxis: {
						title: {
							style: {
								fontSize: "12px", // Further reduce font size on mobile
							},
						},
						labels: {
							style: {
								fontSize: "8px", // Further reduce label font size on mobile
							},
						},
					},
				},
			},
		],
	};

	const chartSeriesBar = [
		{
			name: "Performance",
			data: allSavingPerformances.map((data) => data.Return * 100),
		},
	];

	const [chartOptions, setChartOptions] = useState({});
	const [chartSeries, setChartSeries] = useState([]);

	useEffect(() => {
		// Step 1: Debug and check the data structure
		console.log(
			"allTableMonthSavingPerformances:",
			allTableMonthSavingPerformances
		);

		if (
			!allTableMonthSavingPerformances ||
			allTableMonthSavingPerformances.length === 0
		) {
			console.error("No data available in allTableMonthSavingPerformances.");
			return;
		}

		// Step 2: Get unique years and months
		const years = [
			...new Set(
				allTableMonthSavingPerformances.map((item) => String(item?.Year))
			),
		];
		console.log("Extracted years:", years);

		const months = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];
		console.log("Months:", months);

		// Step 3: Prepare the series data for each year
		const series = years.map((year) => {
			return {
				name: `${year}`,
				data: months.map((month) => {
					// Find the matching data for each year and month
					const dataForMonth = allTableMonthSavingPerformances.find(
						(item) =>
							String(item?.Year) === String(year) && item?.month === month
					);

					console.log(`Data for ${month} ${year}:`, dataForMonth);

					return {
						x: month,
						// If data is found, format the return value, otherwise set null
						y: dataForMonth ? parseFloat(dataForMonth.return).toFixed(2) : 0,
					};
				}),
			};
		});

		// Log series data to verify the structure
		console.log("Prepared Series:", series);

		// Step 4: Set chart options with necessary configurations
		setChartOptions({
			chart: {
				type: "heatmap",
				height: window.innerWidth <= 640 ? "300px" : "350px", // Adjust height for mobile
				toolbar: {
					show: false,
				},
			},
			xaxis: {
				categories: months,
				title: {
					// text: "Months",
					style: {
						fontSize: window.innerWidth <= 640 ? "10px" : "12px", // Smaller font size for mobile
					},
				},
			},
			yaxis: {
				labels: {
					style: {
						fontSize: window.innerWidth <= 640 ? "10px" : "12px", // Adjust font size
					},
				},
			},
			colors: ["#37ad55"], // You can adjust the color as per your preference
			dataLabels: {
				enabled: true,
				formatter: (val) => `${val}%`, // Add percentage sign
				style: {
					colors: ["#000"], // Black text
					fontSize: window.innerWidth <= 640 ? "6px" : "10px", // Smaller font size for mobile
				},
			},
			stroke: {
				width: 1,
			},
		});

		// Step 5: Set the chart series data
		setChartSeries(series);
	}, [allTableMonthSavingPerformances]); // Run effect when data changes

	return (
		<div className="container">
			<div>
				{/* Fixed back icon */}
				<ArrowLeftOutlined
					onClick={handleBack} // Navigate back to the previous page
					style={{
						top: 10, // Distance from the top
						fontSize: "24px", // Icon size
						cursor: "pointer", // Change the cursor to pointer on hover
						marginBottom: "10px",
						marginTop: "10px",
					}}
				/>

				<Card style={{ marginBottom: "24px", marginTop: "24px" }}>
					<Title level={4}>Performance {productNameUpdate}</Title>
					<Divider />
					<Chart
						options={chartOptionsBar}
						series={chartSeriesBar}
						type="line"
						height={350}
					/>
				</Card>
				<Table
					dataSource={allTableYearSavingPerformances}
					columns={columns}
					rowKey="_id" // Use _id as the unique row key
					pagination={false} // Disable pagination if not needed
					scroll={{ x: "max-content" }}
					className="custom-table" // Apply custom CSS class for styling
					style={{ marginBottom: "24px", marginTop: "24px" }}
				/>
				<Card style={{ marginBottom: "24px", marginTop: "24px" }}>
					<Title level={4}>Monthly Returns Heatmap</Title>
					<Chart
						options={chartOptions}
						series={chartSeries}
						type="heatmap"
						height="350px"
					/>
				</Card>

				<section>
					<ResponsiveMenuDrawer
						profileType={profileType} // Pass profileType dynamically here
						handleMenuClick={handleMenuClick}
						handleHomeClick={handleHomeClick}
						handleStrategiesClick={handleStrategiesClick}
						handleExchangesClick={handleExchangesClick}
						handleAcademyClick={handleAcademyClick}
						handleAdmin={handleAdmin}
						handleSettingsClick={handleSettingsClick}
						handleSignOut={handleSignOut}
					/>
				</section>
			</div>
		</div>
	);
};

export default StrategyDetails;
