import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Typography, Layout } from "antd";
import ResponsiveMenuDrawer from "../components/ResponsiveMenuDrawer.js"; // Adjust the path
import { ArrowLeftOutlined } from "@ant-design/icons";

const Disclaimer = () => {
	const { Title, Paragraph } = Typography;
	const { Content } = Layout;

	const navigate = useNavigate();

	const handleBack = () => {
		navigate("/MySettings");
	};

	const handleSettingsClick = () => {
		navigate("/MySettings");
	};

	const [loginUser, setLoginUser] = useState("");

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user"));
		if (user) {
			setLoginUser(user);
		}
	}, []);

	const profileType = loginUser.profileType;

	// Define a function to handle button clicks and log the corresponding action
	const handleClick = (action) => {
		console.log(`${action} button clicked!`);
	};

	// Define a function to handle button clicks and log the corresponding action
	const handleMenuClick = (e) => console.log("Menu item clicked:", e.key);

	const handleHomeClick = () => {
		navigate("/");
	};

	const handleStrategiesClick = () => console.log("Strategies clicked");

	const handleExchangesClick = () => {
		navigate("/MyInvestments");
	};

	const handleAcademyClick = () => {
		navigate("/FinanceLiteracy");
	};

	const handleAdmin = () => {
		navigate("/ExplorerSettings");
	};

	const handleSignOut = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("investorProfile");
		navigate("/login");
	};

	return (
		<div className="container">
			<div>
				{/* Fixed back icon */}
				<ArrowLeftOutlined
					onClick={handleBack} // Navigate back to the previous page
					style={{
						top: 10, // Distance from the top
						fontSize: "24px", // Icon size
						cursor: "pointer", // Change the cursor to pointer on hover
						marginBottom: "10px",
						marginTop: "10px",
					}}
				/>
				<section>
					<Card
						style={{
							maxWidth: "100%",
							width: "100%",
							boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
							borderRadius: 8,
							backgroundColor: "#ffffff",
						}}
					>
						<Typography>
							<Title
								level={2}
								style={{
									textAlign: "left",
									marginBottom: "24px",
									color: "#333",
								}}
							>
								Disclaimer
							</Title>
							<Paragraph
								style={{ fontSize: "21px", lineHeight: "1.6", color: "#555" }}
							>
								<strong>No Investment Advice Provided</strong>
							</Paragraph>
							<Paragraph
								style={{ fontSize: "16px", lineHeight: "1.6", color: "#555" }}
							>
								Trading leveraged products is risky, and you could lose more
								than your initial deposit. Any opinions, chats, messages, news,
								research, analyses, prices, or other information on this Website
								are provided solely for educational and entertainment purposes
								and do not constitute investment advice.
							</Paragraph>
							<Paragraph
								style={{ fontSize: "16px", lineHeight: "1.6", color: "#555" }}
							>
								Before making actual trading decisions, the Website should not
								be used as a substitute for extensive independent market
								research. Opinions, market data, recommendations, and other
								content are subject to change without notice at any time. Stock
								Events will not accept liability for any loss or damage,
								including loss of profit, resulting directly or indirectly from
								the use of or reliance on such information.
							</Paragraph>
							<Paragraph
								style={{ fontSize: "16px", lineHeight: "1.6", color: "#555" }}
							>
								<strong>
									PAST PERFORMANCE IS NOT NECESSARILY INDICATIVE OF FUTURE
									RESULTS.
								</strong>
							</Paragraph>
						</Typography>
					</Card>
				</section>

				<section>
					<ResponsiveMenuDrawer
						profileType={profileType} // Pass profileType dynamically here
						handleMenuClick={handleMenuClick}
						handleHomeClick={handleHomeClick}
						handleStrategiesClick={handleStrategiesClick}
						handleExchangesClick={handleExchangesClick}
						handleAcademyClick={handleAcademyClick}
						handleSettingsClick={handleSettingsClick}
						handleAdmin={handleAdmin}
						handleSignOut={handleSignOut}
					/>
				</section>
			</div>
		</div>
	);
};

export default Disclaimer;
