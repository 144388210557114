import React, { useState, useEffect } from "react";
import {
	Tabs,
	Form,
	Input,
	Typography,
	Col,
	Space,
	Card,
	Row,
	message,
	Button,
	Spin,
} from "antd";
import { useNavigate } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import axios from "axios";
import Spinner from "../components/Spinner";

import moment from "moment";
import {
	ArrowLeftOutlined,
	CheckCircleOutlined,
	EditOutlined,
	FilePdfOutlined,
	LockOutlined,
} from "@ant-design/icons";

import ResponsiveMenuDrawer from "../components/ResponsiveMenuDrawer.js"; // Adjust the path

const ResetPassword = () => {
	const { Title, Text } = Typography;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const navigate = useNavigate();

	const { TabPane } = Tabs;
	const { TextArea } = Input;

	const [allSavingStats, setAllSavingStats] = useState([]);

	const [loading, setLoading] = useState(false);

	const [activeTab, setActiveTab] = useState("profile");

	const [form] = Form.useForm();

	const handleTabChange = (key) => {
		setActiveTab(key);
	};

	// Assuming contains the URL
	const openPopup = (url) => {
		const popup = window.open(url, "popup", "width=600,height=600");
		if (popup) {
			popup.focus();
			// console.log(url);
		}
	};

	//Handlers User  **************************************************************************************************************************
	// useEffect(() => {
	//   const fetchUserData = async () => {
	//     try {
	//       const user = JSON.parse(localStorage.getItem('user'));
	//       setLoading(true);
	//       const res = await axios.post('/users/get-user',
	//       { userid: user._id },
	//       { headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${user.token}` } });
	//       setAllSavingStats(res.data[0]);
	//       // Format birthdayDate using moment
	//       const formattedDate = moment(res.data[0].birthdayDate).format('YYYY-MM-DD');
	//       // Set form fields value
	//       form.setFieldsValue({
	//         name: res.data[0].name,
	//         birthdayDate: formattedDate, // Set formatted date
	//         nif: res.data[0].nif,
	//         addressLiving: res.data[0].addressLiving,
	//         postalCodeLiving: res.data[0].postalCodeLiving,
	//         cityLiving: res.data[0].cityLiving,
	//         stateLiving: res.data[0].stateLiving,
	//         email: res.data[0].email,
	//         phoneNumber: res.data[0].phoneNumber,
	//         currency: res.data[0].currency,
	//       });
	//       console.log(res.data[0]);
	//       setLoading(false);
	//     } catch (error) {
	//       setLoading(false);
	//       message.error('Fetch Issue With account');
	//     }
	//   };

	//   fetchUserData();
	// }, [form]);

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				if (!user) {
					message.error("No user found in localStorage");
					return;
				}

				setLoading(true);

				const res = await axios.post(
					"/users/get-user",
					{ userid: user._id },
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);

				if (res.data && res.data.length > 0) {
					const userData = res.data[0];
					setAllSavingStats(userData);

					// Format birthdayDate using moment
					const formattedDate = moment(userData.birthdayDate).format(
						"YYYY-MM-DD"
					);

					// Set form fields value
					form.setFieldsValue({
						name: userData.firstName + " " + userData.lastName,
						countryCitizenship: userData.countryCitizenship,
						birthdayDate: formattedDate,
						nif: userData.nif,
						email: userData.email,
						phoneNumber: userData.phoneNumber,
						currency: userData.currency,
					});
				} else {
					message.error("No user data found");
				}

				setLoading(false);
			} catch (error) {
				setLoading(false);
				message.error("Fetch Issue With account");
			}
		};

		fetchUserData();
	}, [form]);

	const handleSubmitPassword = async (values) => {
		try {
			const user = JSON.parse(localStorage.getItem("user"));

			setLoading(true);
			const res = await axios.post(
				"/users/edit-user",
				{
					payload: {
						...values,
					},
					_id: user._id,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			setLoading(false);
			message.success("Password updated successfully!");
			navigate("/");
			// console.log(values)
		} catch (error) {
			setLoading(false);
			// message.error('Failed to industry');
			// message.error('You are not Admin User');
		}
	};

	const handleSubmitProfile = async (values) => {
		try {
			const user = JSON.parse(localStorage.getItem("user"));

			setLoading(true);
			const res = await axios.post(
				"/users/edit-user",
				{
					payload: {
						...values,
					},
					_id: user._id,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			setLoading(false);
			message.success("Personal Data updated successfully!");
			navigate("/MySettings");
			// console.log(values)
		} catch (error) {
			setLoading(false);
			// message.error('Failed to industry');
			// message.error('You are not Admin User');
		}
	};
	// const onFinish = (values) => {
	//   console.log({...values.serviceRating
	//   });
	// };

	const currencyOptions = [
		{ value: "USD", label: "USD" },
		{ value: "EUR", label: "EUR" },
		{ value: "GBP", label: "GBP" },
		{ value: "CHF", label: "CHF" },
		{ value: "BRL", label: "BRL" },
	];

	//DELETE ACCOUNT **************************************************************************************************************************

	const handleDelete = async (values) => {
		try {
			setLoading(true);
			const user = JSON.parse(localStorage.getItem("user"));

			const res1 = axios.post(
				"/accounts/delete-account",
				{
					userid: user._id,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);

			const res2 = axios.post(
				"/accounts/delete-all-account/" + user._id,
				{},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);

			setLoading(false);

			localStorage.removeItem("user");
			localStorage.removeItem("investorProfile");

			navigate("/login");
		} catch (error) {
			setLoading(false);
			message.error("Algo deu errado!");
		}
	};

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				if (!user) {
					message.error("No user found in localStorage");
					return;
				}

				setLoading(true);

				const res = await axios.post(
					"/users/get-user",
					{ userid: user._id },
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);

				if (res.data && res.data.length > 0) {
					const userData = res.data[0];
					setAllSavingStats(userData);

					// Format birthdayDate using moment
					const formattedDate = moment(userData.birthdayDate).format(
						"YYYY-MM-DD"
					);

					// Set form fields value
					form.setFieldsValue({
						name: userData.firstName + " " + userData.lastName,
						countryCitizenship: userData.countryCitizenship,
						birthdayDate: formattedDate,
						nif: userData.nif,
						email: userData.email,
						phoneNumber: userData.phoneNumber,
						currency: userData.currency,
					});
				} else {
					message.error("No user data found");
				}

				setLoading(false);
			} catch (error) {
				setLoading(false);
				message.error("Fetch Issue With account");
			}
		};

		fetchUserData();
	}, [form]);

	const handleBack = () => {
		navigate("/MySettings");
	};

	// Define a function to handle button clicks and log the corresponding action
	const handleMenuClick = (e) => console.log("Menu item clicked:", e.key);

	const handleHomeClick = () => {
		navigate("/");
	};

	const handleStrategiesClick = () => console.log("Strategies clicked");

	const handleExchangesClick = () => {
		navigate("/MyInvestments");
	};

	const handleAcademyClick = () => {
		navigate("/FinanceLiteracy");
	};

	const handleAdmin = () => {
		navigate("/ExplorerSettings");
	};

	const handleSettingsClick = () => {
		navigate("/MySettings");
	};

	const handleSignOut = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("investorProfile");
		navigate("/login");
	};

	const [loginUser, setLoginUser] = useState("");
	useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user"));
		if (user) {
			setLoginUser(user);
		}
	}, []);

	const profileType = loginUser.profileType;

	return (
		<div className="container" style={{ height: "75vh" }}>
			{loading && <Spin />}
			<div>
				{/* Fixed back icon */}
				<ArrowLeftOutlined
					onClick={handleBack} // Navigate back to the previous page
					style={{
						top: 10, // Distance from the top
						fontSize: "24px", // Icon size
						cursor: "pointer", // Change the cursor to pointer on hover
						marginBottom: "10px",
						marginTop: "10px",
					}}
				/>
				<section>
					<Card style={{ marginTop: "16px", width: "100%" }}>
						<Title
							level={4}
							style={{ marginBottom: "20px", marginTop: "20px" }}
						>
							Change Password
						</Title>
						<Form
							layout="vertical"
							name="passwordForm"
							onFinish={handleSubmitPassword}
						>
							{/* New Password */}
							<Form.Item
								name="password"
								label="Password"
								rules={[
									{
										required: true,
										message: "Please enter your new password",
									},
									{
										min: 8,
										message: "Password must be at least 8 characters",
									},
									{
										pattern: /[A-Z]/,
										message:
											"Password must contain at least one uppercase letter",
									},
									{
										pattern: /\d/,
										message: "Password must contain at least one number",
									},
									{
										pattern: /[!@#$%^&*(),.?":{}|<>]/,
										message:
											"Password must contain at least one special character (!, @, #, $, %, ^, &, *, etc.)",
									},
								]}
							>
								<Input.Password
									placeholder="Enter your new password"
									prefix={<LockOutlined />}
									size="large"
									style={{ borderRadius: "8px" }}
								/>
							</Form.Item>

							{/* Confirm Password */}
							<Form.Item
								name="confirmPassword"
								label="Confirm Password"
								rules={[
									{
										required: true,
										message: "Please confirm your password",
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue("password") === value) {
												return Promise.resolve();
											}
											return Promise.reject("The two passwords do not match");
										},
									}),
								]}
							>
								<Input.Password
									placeholder="Confirm your new password"
									prefix={<CheckCircleOutlined />}
									size="large"
									style={{ borderRadius: "8px" }}
								/>
							</Form.Item>

							{/* Submit Button */}
							<Form.Item>
								<Button
									htmlType="submit"
									size="large"
									block
									loading={loading}
									className="button-app"
								>
									Change Password
								</Button>
							</Form.Item>
						</Form>
					</Card>
				</section>

				{/* Account Details Section */}
				{/* <Card style={{ marginTop: "16px", width: "100%" }}>
					<Title level={4} style={{ marginBottom: "20px", marginTop: "20px" }}>
						Change Password
					</Title>
					<Form
						layout="vertical"
						name="passwordForm"
						onFinish={handleSubmitPassword}
					>
						<Form.Item
							style={{ width: "100%" }}
							label="Password"
							name={"password"}
							rules={[
								{
									required: true,
									message: "Please enter your password",
								},
							]}
						>
							<Input.Password />
						</Form.Item>
						<Form.Item
							style={{ width: "100%" }}
							label="Repeat Password"
							name="confirmPassword"
							dependencies={["password"]}
							rules={[
								{
									required: true,
									message: "Please check your password",
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue("password") === value) {
											return Promise.resolve();
										}
										return Promise.reject(
											new Error("The two passwords do not match!")
										);
									},
								}),
							]}
						>
							<Input.Password />
						</Form.Item>

						<Form.Item>
							<Button
								style={{ width: "100%", marginTop: "20px" }}
								type="primary"
								htmlType="submit"
							>
								UPDATE PASSWORD
							</Button>
							<Button
								style={{
									width: "100%",
									background: "white",
									color: "Blue",
									marginTop: 30,
									marginBottom: 30,
								}}
								type="primary"
								htmlType="submit"
								onClick={() => navigate("/MySettings")}
							>
								CANCEL
							</Button>
						</Form.Item>
					</Form>
					<Card style={{ marginTop: "16px", width: "100%" }}></Card>
				</Card> */}

				<section>
					<ResponsiveMenuDrawer
						profileType={profileType} // Pass profileType dynamically here
						handleMenuClick={handleMenuClick}
						handleHomeClick={handleHomeClick}
						handleStrategiesClick={handleStrategiesClick}
						handleExchangesClick={handleExchangesClick}
						handleAcademyClick={handleAcademyClick}
						handleAdmin={handleAdmin}
						handleSettingsClick={handleSettingsClick}
						handleSignOut={handleSignOut}
					/>
				</section>
			</div>
		</div>
	);
};

export default ResetPassword;
