import React, { useState, useEffect } from "react";
import {
	Tabs,
	Form,
	Input,
	Col,
	Spin,
	Card,
	Row,
	message,
	Button,
	Select,
	Typography,
	Divider,
	Modal,
} from "antd";
import { useNavigate } from "react-router-dom";

import ResponsiveMenuDrawer from "../components/ResponsiveMenuDrawer.js"; // Adjust the path
import axios from "axios";

import moment from "moment";
import {
	ArrowLeftOutlined,
	EditOutlined,
	FilePdfOutlined,
} from "@ant-design/icons";

const MyAppInfo = () => {
	const { Title, Text, Link } = Typography;

	const [loginUser, setLoginUser] = useState("");
	useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user"));
		if (user) {
			setLoginUser(user);
		}
	}, []);

	const profileType = loginUser.profileType;

	const navigate = useNavigate();

	const { TabPane } = Tabs;
	const { TextArea } = Input;

	const [allUser, setAllUser] = useState([]);

	const [loading, setLoading] = useState(false);

	// State to control modal visibility
	const [isModalVisible, setIsModalVisible] = useState(false);

	// Handlers for opening and closing the modal
	const showModal = () => setIsModalVisible(true);
	const handleCancel = () => setIsModalVisible(false);

	const handleDelete = async (values) => {
		try {
			setLoading(true);
			const user = JSON.parse(localStorage.getItem("user"));

			const res1 = axios.post(
				"/accounts/delete-account",
				{
					userid: user._id,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);

			const res2 = axios.post(
				"/accounts/delete-all-account/" + user._id,
				{},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);

			setLoading(false);

			localStorage.removeItem("user");
			localStorage.removeItem("investorProfile");

			navigate("/login");
		} catch (error) {
			setLoading(false);
			message.error("Algo deu errado!");
		}
	};

	//useEffect Hook
	useEffect(() => {
		const getAllUser = async (values) => {
			try {
				const user = JSON.parse(localStorage.getItem("user"));
				setLoading(true);
				const res = await axios.post(
					"/users/get-user",
					{
						userid: user._id,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
						},
					}
				);
				setLoading(false);

				if (res.data && res.data.length > 0) {
					const userData = res.data[0];
					setAllUser(userData);
				}

				// console.log(res.data);
			} catch (error) {
				setLoading(false);
				//message.error('Fetch Issue With account');
			}
		};

		// Clean up the interval when the component unmounts
		return () => getAllUser();
	}, []);

	const handleBack = () => {
		navigate("/MySettings");
	};

	const handleSettingsClick = () => {
		navigate("/MySettings");
	};

	// Define a function to handle button clicks and log the corresponding action
	const handleMenuClick = (e) => console.log("Menu item clicked:", e.key);

	const handleHomeClick = () => {
		navigate("/");
	};

	const handleStrategiesClick = () => console.log("Strategies clicked");

	const handleExchangesClick = () => {
		navigate("/MyInvestments");
	};

	const handleAcademyClick = () => {
		navigate("/FinanceLiteracy");
	};

	const handleAdmin = () => {
		navigate("/ExplorerSettings");
	};

	const handlePrivacy = () => {
		navigate("/PrivacyPolicies");
	};

	const handleTermService = () => {
		navigate("/TermsService");
	};

	const handleSignOut = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("investorProfile");
		navigate("/login");
	};

	return (
		<div className="container">
			{loading && <Spin />}
			<div>
				{/* Fixed back icon */}
				<ArrowLeftOutlined
					onClick={handleBack} // Navigate back to the previous page
					style={{
						top: 10, // Distance from the top
						fontSize: "24px", // Icon size
						cursor: "pointer", // Change the cursor to pointer on hover
						marginBottom: "10px",
						marginTop: "10px",
					}}
				/>

				{/* Section 2: Cryptocurrency Table */}
				<section>
					<Card style={{ marginTop: "16px", width: "100%" }}>
						<Title
							level={4}
							style={{ marginBottom: "20px", marginTop: "20px" }}
						>
							Application Version
						</Title>
						<Card style={{ marginTop: "16px", width: "100%" }}>
							<Title level={4}>Versão 25.1.1</Title>
							<Text>Last updated: January 26, 2025</Text>
						</Card>

						<Card
							title="Last Update"
							bordered={false}
							style={{ marginBottom: "20px" }}
						>
							<Text>
								The latest version of the app was released on January 26th 2025.
								Includes performance improvements and bug fixes.
							</Text>
						</Card>

						<Divider />

						<Card
							title="General Conditions of Use"
							bordered={false}
							style={{ marginBottom: "20px" }}
						>
							<Text>
								By using our application, you agree to our terms of use.
								<br />
								<br />
								<Link onClick={handleTermService} style={{ cursor: "pointer" }}>
									Read the full Terms of Use
								</Link>
							</Text>
						</Card>

						<Card
							title="Privacy and Personal Data Protection Policy"
							bordered={false}
							style={{ marginBottom: "20px" }}
						>
							<Text>
								We take your privacy seriously. Please read our policy privacy
								policy to understand how your data is protected.
								<br />
								<br />
								<Link onClick={handlePrivacy} style={{ cursor: "pointer" }}>
									Read the full Privacy Policy
								</Link>
							</Text>
						</Card>
					</Card>
				</section>
			</div>
			<section>
				<ResponsiveMenuDrawer
					profileType={profileType} // Pass profileType dynamically here
					handleMenuClick={handleMenuClick}
					handleHomeClick={handleHomeClick}
					handleStrategiesClick={handleStrategiesClick}
					handleExchangesClick={handleExchangesClick}
					handleAcademyClick={handleAcademyClick}
					handleAdmin={handleAdmin}
					handleSettingsClick={handleSettingsClick}
					handleSignOut={handleSignOut}
				/>
			</section>
		</div>
	);
};

export default MyAppInfo;
