import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Typography, Layout } from "antd";
import ResponsiveMenuDrawer from "../components/ResponsiveMenuDrawer.js"; // Adjust the path
import { ArrowLeftOutlined } from "@ant-design/icons";

const TermsService = () => {
	const { Title, Paragraph } = Typography;
	const { Content } = Layout;

	const navigate = useNavigate();

	const handleBack = () => {
		navigate("/MySettings");
	};

	const handleSettingsClick = () => {
		navigate("/MySettings");
	};

	const [loginUser, setLoginUser] = useState("");

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user"));
		if (user) {
			setLoginUser(user);
		}
	}, []);

	const profileType = loginUser.profileType;

	// Define a function to handle button clicks and log the corresponding action
	const handleClick = (action) => {
		console.log(`${action} button clicked!`);
	};

	// Define a function to handle button clicks and log the corresponding action
	const handleMenuClick = (e) => console.log("Menu item clicked:", e.key);

	const handleHomeClick = () => {
		navigate("/");
	};

	const handleStrategiesClick = () => console.log("Strategies clicked");

	const handleExchangesClick = () => {
		navigate("/MyInvestments");
	};

	const handleAcademyClick = () => {
		navigate("/FinanceLiteracy");
	};

	const handleAdmin = () => {
		navigate("/ExplorerSettings");
	};

	const handleSignOut = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("investorProfile");
		navigate("/login");
	};

	return (
		<div className="container">
			<div>
				{/* Fixed back icon */}
				<ArrowLeftOutlined
					onClick={handleBack} // Navigate back to the previous page
					style={{
						top: 10, // Distance from the top
						fontSize: "24px", // Icon size
						cursor: "pointer", // Change the cursor to pointer on hover
						marginBottom: "10px",
						marginTop: "10px",
					}}
				/>
				<section>
					<Card
						style={{
							maxWidth: "100%",
							width: "100%",
							boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
							borderRadius: 8,
							backgroundColor: "#ffffff",
						}}
					>
						<Typography>
							<Title
								level={2}
								style={{
									textAlign: "left",
									marginBottom: "24px",
									color: "#333",
								}}
							>
								Terms of Service
							</Title>
							<Paragraph
								style={{ fontSize: "16px", lineHeight: "1.6", color: "#555" }}
							>
								By downloading or using the app, these terms will automatically
								apply to you – you should make sure therefore that you read them
								carefully before using the app. You’re not allowed to copy, or
								modify the app, any part of the app, or our trademarks in any
								way. You’re not allowed to attempt to extract the source code of
								the app, and you also shouldn’t try to translate the app into
								other languages, or make derivative versions. The app itself,
								and all the trademarks, copyright, database rights and other
								intellectual property rights related to it, still belong to
								Company, Lda.
							</Paragraph>
							<Paragraph
								style={{ fontSize: "16px", lineHeight: "1.6", color: "#555" }}
							>
								Company, Lda is committed to ensuring that the app is as useful
								and efficient as possible. For that reason, we reserve the right
								to make changes to the app or to charge for its services, at any
								time and for any reason. We will never charge you for the app or
								its services without making it very clear to you exactly what
								you’re paying for.
							</Paragraph>
							<Title level={4} style={{ marginTop: "21px", color: "#333" }}>
								Responsibility and Security
							</Title>
							<Paragraph
								style={{ fontSize: "16px", lineHeight: "1.6", color: "#555" }}
							>
								The Bit-Advisor app stores and processes personal data that you
								have provided to us, in order to provide our Service. It’s your
								responsibility to keep your phone and access to the app secure.
								We therefore recommend that you do not jailbreak or root your
								phone, which is the process of removing software restrictions
								and limitations imposed by the official operating system of your
								device. It could make your phone vulnerable to
								malware/viruses/malicious programs, compromise your phone’s
								security features and it could mean that the Bit-Advisor app
								won’t work properly or at all.
							</Paragraph>
							<Title level={4} style={{ marginTop: "21px", color: "#333" }}>
								Liability
							</Title>
							<Paragraph
								style={{ fontSize: "16px", lineHeight: "1.6", color: "#555" }}
							>
								You should be aware that there are certain things that
								BI-Capital, Lda will not take responsibility for. Certain
								functions of the app will require the app to have an active
								internet connection. The connection can be Wi-Fi, or provided by
								your mobile network provider, but Company, Lda cannot take
								responsibility for the app not working at full functionality if
								you don’t have access to Wi-Fi, and you don’t have any of your
								data allowance left.
							</Paragraph>
							<Paragraph
								style={{ fontSize: "16px", lineHeight: "1.6", color: "#555" }}
							>
								If you’re using the app outside of an area with Wi-Fi, you
								should remember that your terms of the agreement with your
								mobile network provider will still apply. As a result, you may
								be charged by your mobile provider for the cost of data for the
								duration of the connection while accessing the app, or other
								third party charges. In using the app, you’re accepting
								responsibility for any such charges, including roaming data
								charges if you use the app outside of your home territory (i.e.,
								region or country) without turning off data roaming. If you are
								not the bill payer for the device on which you’re using the app,
								please be aware that we assume that you have received permission
								from the bill payer for using the app.
							</Paragraph>
							<Title level={4} style={{ marginTop: "21px", color: "#333" }}>
								Changes to Terms
							</Title>
							<Paragraph
								style={{ fontSize: "16px", lineHeight: "1.6", color: "#555" }}
							>
								We may update our Terms and Conditions from time to time. Thus,
								you are advised to review this page periodically for any
								changes. We will notify you of any changes by posting the new
								Terms and Conditions on this page. These changes are effective
								immediately after they are posted on this page.
							</Paragraph>
							<Title level={4} style={{ marginTop: "21px", color: "#333" }}>
								Contact Us
							</Title>
							<Paragraph
								style={{ fontSize: "16px", lineHeight: "1.6", color: "#555" }}
							>
								If you have any questions or suggestions about our Terms and
								Conditions, do not hesitate to contact us at{" "}
								<a href="mailto:support@company-lda.pt">
									support@company-lda.pt
								</a>
							</Paragraph>
						</Typography>
					</Card>
				</section>

				<section>
					<ResponsiveMenuDrawer
						profileType={profileType} // Pass profileType dynamically here
						handleMenuClick={handleMenuClick}
						handleHomeClick={handleHomeClick}
						handleStrategiesClick={handleStrategiesClick}
						handleExchangesClick={handleExchangesClick}
						handleAcademyClick={handleAcademyClick}
						handleAdmin={handleAdmin}
						handleSettingsClick={handleSettingsClick}
						handleSignOut={handleSignOut}
					/>
				</section>
			</div>
		</div>
	);
};

export default TermsService;
